/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { App } from '../models/App';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppService {

    /**
     * Create
     * @param name
     * @param requestBody
     * @returns App Successful Response
     * @throws ApiError
     */
    public static createApiV1AppPost(
        name: string,
        requestBody?: Array<string>,
    ): CancelablePromise<App> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app',
            query: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Apps
     * @returns App Successful Response
     * @throws ApiError
     */
    public static listAppsApiV1AppGet(): CancelablePromise<Array<App>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app',
        });
    }

}
