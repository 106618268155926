import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {EmployeeLdap} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {ROUTE_PATHS} from 'constant';
import {isSuperUser} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {ReactNode} from 'react';

import {useTrackers, useWorkstreamReports, useWorkstreamSelf} from './hooks';

const TrackerCard = ({
  name,
  id,
  owner,
  workstreams,
  showUpdateButton,
  showAvatar,
}: {
  name: ReactNode;
  owner: EmployeeLdap;
  workstreams: number;
  id: string | number;
  showAvatar?: boolean;
  showUpdateButton?: boolean;
}) => (
  <Link to={`/workstreams/${id}`} hasNoUnderline isClickable={!!id}>
    <Box
      {...withShade({
        className: atoms({
          padding: '16',
          borderRadius: 'Medium',
          borderColor: 'Border Subtle',
          borderWidth: '1',
          borderStyle: 'Solid',
        }),
        style: {
          height: 106,
        },
      })}
    >
      <Stack gap="8">
        <LabelGroup
          align="top"
          withText={<Text isBold>{name}</Text>}
          withLeftAccessory={
            showAvatar &&
            (owner?.ldap ? <Avatar size="small" user={owner} /> : <Skeleton.Avatar size="small" />)
          }
          withSubtext={<Text size="xsmall">{t('workstreams_count', {count: workstreams})}</Text>}
        />
        {showUpdateButton && (
          <Button size="small" variant="primary" style={{float: 'right'}}>
            {t('update_all')}
          </Button>
        )}
      </Stack>
    </Box>
  </Link>
);

const TrackerAddButton = () => {
  return (
    <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button {...getTriggerProps()} variant="outline" withDropdownIcon>
            {t('tracker_add')}
          </Button>
          <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom-end">
            <Menu.Segment>
              <Menu.LinkItem href={ROUTE_PATHS.WORKSTREAM_TRACKER_NEW}>
                {t('add_tracker')}
              </Menu.LinkItem>
              <Menu.LinkItem href={ROUTE_PATHS.WORKSTREAM_NEW}>{t('add_workstream')}</Menu.LinkItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const Trackers = () => {
  const {employee, directReports} = useAtomValue(loggedInEmployeeAtom);
  const {data: trackers} = useTrackers();
  const {data: myWorkstreams} = useWorkstreamSelf();
  const {data: myReports} = useWorkstreamReports();

  const user = useAtomValue(pulseUserAtom);
  if (!user || (user && !isSuperUser(user.email))) {
    return null;
  }

  return (
    <Layout.Container>
      <Stack gap="24">
        <Split>
          <Split.Item width="fill">
            <Title size={24}>{t('workstreams')}</Title>
          </Split.Item>
          <Split.Item>
            <TrackerAddButton />
          </Split.Item>
        </Split>
        <Title size={16}>{t('trackers')}</Title>
      </Stack>

      <Box
        className={atoms({marginTop: '12'})}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(285px, 1fr))',
          gap: 12,
        }}
      >
        <TrackerCard
          name={employee?.ldap ? employee.name : <Skeleton.Box width={160} height={20} />}
          id={employee.ldap}
          owner={employee}
          workstreams={myWorkstreams?.length ?? 0}
          showUpdateButton
          showAvatar
        />
        {directReports.length >= 1 && (
          <TrackerCard
            name={t('direct_reports')}
            id={`${employee.ldap}/reports}`}
            owner={employee}
            workstreams={myReports?.length ?? 0}
          />
        )}

        {trackers?.map((tracker) => {
          return (
            <TrackerCard
              key={tracker.id}
              name={tracker.name}
              id={tracker.id}
              owner={tracker.owner}
              workstreams={tracker.workstreams.length}
            />
          );
        })}
      </Box>
    </Layout.Container>
  );
};
