import {useMutation, useSuspenseQuery} from '@tanstack/react-query';
import {
  Comment,
  CommentCreate,
  CommentService,
  CommentUpdate,
  Goal,
  Thread,
  ThreadCreate,
  ThreadService,
  ThreadUpdate,
} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

const threadService = getService(ThreadService);

export const useGoalThreads = ({
  goalId,
  shouldRefetch,
  disabled,
}: {
  goalId: Goal['id'];
  shouldRefetch?: boolean;
  disabled?: boolean;
  withError?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['threads', goalId],
    queryFn: !disabled
      ? () => threadService.getThreadsForGoalApiV1ThreadGoalGoalIdGet(goalId)
      : async () => Promise.resolve([]),
    refetchInterval: shouldRefetch ? 1000 * 30 : Infinity, // 30 seconds
    refetchOnWindowFocus: 'always',
  });

  data?.forEach((thread) => queryClient.setQueryData<Thread>(['thread', thread.id], thread));

  return data as Thread[];
};

export const useThreadService = () => {
  const onSettled = (response: Thread | undefined, _error: any, _variables: any, context: any) => {
    if (!response) {
      return;
    }
    queryClient.setQueryData(['thread', response.id], response);

    const goalId = response.goal_id;

    queryClient.setQueryData(['threads', goalId], (old: Thread[] | undefined) => {
      if (!old) {
        queryClient.invalidateQueries({queryKey: ['threads', goalId]});
        return;
      }

      let threads = [...old];
      if (context?.type === 'create') {
        threads = [...threads, response];
      } else if (context.type === 'delete') {
        threads = threads.filter((thread) => thread.id !== response.id);
      } else if (context.type === 'update') {
        // threads = threads.filter((thread) => thread.id !== response.id);
        threads = threads.map((thread) => (thread.id === response.id ? response : thread));
      }

      return threads;
    });
  };

  const {mutateAsync: createThread} = useMutation({
    mutationFn: ({
      goalId,
      keyResultId,
      data,
    }: {
      goalId: number;
      keyResultId?: number;
      data: ThreadCreate;
    }) => {
      if (keyResultId) {
        return getService(
          ThreadService
        ).createThreadForKeyResultApiV1ThreadKeyResultKeyResultIdPost(keyResultId, data);
      }
      return getService(ThreadService).createThreadForGoalApiV1ThreadGoalGoalIdPost(goalId, data);
    },
    onMutate: () => ({type: 'create'}),
    onSettled,
  });

  const {mutateAsync: deleteThread} = useMutation({
    mutationFn: ({threadId}: {threadId: string}) =>
      getService(ThreadService).deleteThreadApiV1ThreadThreadIdDelete(threadId),
    onMutate: () => ({type: 'delete'}),
    onSettled,
  });

  const {mutateAsync: updateThread} = useMutation({
    mutationFn: ({threadId, data}: {threadId: string; data: ThreadUpdate}) =>
      getService(ThreadService).updateThreadApiV1ThreadThreadIdPut(threadId, data),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  return {createThread, deleteThread, updateThread};
};

const getUpdatedThread = (
  oldThread: Thread,
  response: Comment,
  type: 'create' | 'update' | 'delete'
) => {
  let updatedComments: Comment[] = oldThread.comments as Comment[]; // Default to the existing key results

  if (type === 'create') {
    updatedComments = [...updatedComments, response];
  } else if (type === 'update') {
    updatedComments = updatedComments.map((comment) =>
      comment.id === response.id ? {...response} : comment
    );
  } else if (type === 'delete') {
    updatedComments = updatedComments.filter((comment) => comment.id !== response.id);
  }

  return {...oldThread, comments: updatedComments};
};

export const useCommentService = ({type, id}: {type: string | number; id: string}) => {
  const onSettled = (response: Comment | undefined, _error: any, _variables: any, context: any) => {
    if (!response) {
      return;
    }
    const threadId = response.thread_id;
    const thread: Thread | undefined = queryClient.getQueryData(['thread', threadId]);

    if (!thread) {
      queryClient.invalidateQueries({queryKey: ['thread', threadId]});
      return;
    }

    // Set the updated goal
    queryClient.setQueryData(
      ['thread', threadId],
      getUpdatedThread(thread, response, context.type)
    );

    // Update the specific thread within the threads list
    queryClient.setQueryData(
      ['threads', type, id],
      (oldThreads: Thread[] | undefined): Thread[] => {
        if (!oldThreads) {
          // If oldThreads is undefined, invalidate the query and return an empty array
          queryClient.invalidateQueries({queryKey: ['threads', type, id]});
          return [];
        }

        const updatedThreads = oldThreads.map((oldThread) => {
          if (oldThread.id === threadId) {
            const updatedThread = getUpdatedThread(thread, response, context.type);

            // Return a new thread with the updated key results
            return updatedThread;
          }

          // For thread that aren't being updated, return them as they are
          return oldThread;
        });

        // Ensure that updatedThreads is always returned as an array of Thread
        return updatedThreads;
      }
    );

    // Temporary workaround that resolves by re-fetching.
    // In theory we could do setQueryData(['goals', ldap], () => {})
    queryClient.invalidateQueries({queryKey: ['threads', type, id]});
  };

  const {mutateAsync: createComment} = useMutation({
    mutationFn: ({data}: {data: CommentCreate}) =>
      getService(CommentService).createCommentReplyApiV1CommentReplyPost(data),
    onMutate: () => ({type: 'create'}),
    onSettled,
  });

  const {mutateAsync: updateComment} = useMutation({
    mutationFn: ({commentId, data}: {commentId: string; data: CommentUpdate}) =>
      getService(CommentService).updateCommentApiV1CommentCommentIdPut(commentId, data),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  const {mutateAsync: deleteComment} = useMutation({
    mutationFn: ({commentId}: {commentId: string}) =>
      getService(CommentService).deleteCommentApiV1CommentCommentIdDelete(commentId),
    onMutate: () => ({type: 'delete'}),
    onSettled,
  });

  return {createComment, deleteComment, updateComment};
};
