/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Thread } from '../models/Thread';
import type { ThreadCreate } from '../models/ThreadCreate';
import type { ThreadUpdate } from '../models/ThreadUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ThreadService {

    /**
     * Get Threads For Goal
     * Retrieve threads.
     * @param goalId
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static getThreadsForGoalApiV1ThreadGoalGoalIdGet(
        goalId: number,
    ): CancelablePromise<Array<Thread>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/thread/goal/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Thread For Goal
     * Create a new thread.
     * @param goalId
     * @param requestBody
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static createThreadForGoalApiV1ThreadGoalGoalIdPost(
        goalId: number,
        requestBody: ThreadCreate,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/thread/goal/{goal_id}',
            path: {
                'goal_id': goalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Threads For Workstream
     * @param workstreamId
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static getThreadsForWorkstreamApiV1ThreadWorkstreamWorkstreamIdGet(
        workstreamId: number,
    ): CancelablePromise<Array<Thread>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/thread/workstream/{workstream_id}',
            path: {
                'workstream_id': workstreamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Thread For Workstream
     * @param workstreamId
     * @param requestBody
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static createThreadForWorkstreamApiV1ThreadWorkstreamWorkstreamIdPost(
        workstreamId: number,
        requestBody: ThreadCreate,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/thread/workstream/{workstream_id}',
            path: {
                'workstream_id': workstreamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Thread For Workstream Update
     * Create a new thread.
     * @param workstreamUpdateId
     * @param requestBody
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static createThreadForWorkstreamUpdateApiV1ThreadWorkstreamUpdatesWorkstreamUpdateIdPost(
        workstreamUpdateId: number,
        requestBody: ThreadCreate,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/thread/workstream/updates/{workstream_update_id}',
            path: {
                'workstream_update_id': workstreamUpdateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Thread For Key Result
     * Create a new thread.
     * @param keyResultId
     * @param requestBody
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static createThreadForKeyResultApiV1ThreadKeyResultKeyResultIdPost(
        keyResultId: number,
        requestBody: ThreadCreate,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/thread/key_result/{key_result_id}',
            path: {
                'key_result_id': keyResultId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Thread
     * Update a thread.
     * @param threadId
     * @param requestBody
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static updateThreadApiV1ThreadThreadIdPut(
        threadId: string,
        requestBody: ThreadUpdate,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/thread/{thread_id}',
            path: {
                'thread_id': threadId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Thread
     * Delete a thread.
     * @param threadId
     * @returns Thread Successful Response
     * @throws ApiError
     */
    public static deleteThreadApiV1ThreadThreadIdDelete(
        threadId: string,
    ): CancelablePromise<Thread> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/thread/{thread_id}',
            path: {
                'thread_id': threadId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
