import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, ChevronRightLine, CompassLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {StrategyItem} from 'atoms/strategies';
import {Link} from 'components/DSYS/Link';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useState} from 'react';
import {ObjectiveLine} from 'views/goals_v2/icons';

import {useStrategy} from './hooks';
import styles from './Overview.module.css';

export const StrategyBox = ({
  timeframe,
  strategy,
  isSubStrategry,
}: {
  timeframe: string;
  strategy: StrategyItem;
  isSubStrategry?: boolean;
}) => {
  const {data} = useStrategy({strategyId: strategy.id.toString(), timeframe});
  const [showSubStrategies, setShowSubStrategies] = useState(false);

  return (
    <Stack
      gap="8"
      className={
        !showSubStrategies
          ? atoms({backgroundColor: 'Background Base', borderRadius: 'Medium'})
          : atoms({
              paddingLeft: '12',
              paddingRight: '20',
              paddingY: '16',
              borderRadius: 'Medium',
              borderStyle: 'Solid',
              borderWidth: '1',
              borderColor: 'Border Subtle',
              backgroundColor: 'Background Subtle',
            })
      }
    >
      <Box
        {...(showSubStrategies
          ? {}
          : withShade({
              className: atoms({
                display: 'block',
                paddingLeft: '12',
                paddingRight: '20',
                paddingY: '16',
                borderStyle: 'Solid',
                borderWidth: '1',
                borderRadius: 'Medium',
                borderColor: 'Border Subtle',
              }),
            }))}
      >
        <Split alignY="center" gap="16">
          <Split.Item width="fill">
            <Link to={`/strategies/${strategy.id}`} hasNoUnderline>
              <Box
                display="flex"
                alignItems="center"
                style={{gap: 4}}
                justifyContent="space-between"
              >
                <LabelGroup
                  align="top"
                  withLeftAccessory={
                    <UIIcon src={CompassLine} className={atoms({color: 'Text Subtle'})} />
                  }
                  withText={
                    <Text size="large" isBold>
                      {strategy.name}
                    </Text>
                  }
                  withSubtext={
                    <Text size="small" color="faint">
                      {strategy.owners.map((owner, index) => (
                        <Link
                          monochromatic
                          key={`${strategy.id}—${owner.ldap}`}
                          to={`/people/${owner.ldap}`}
                          hasNoUnderline
                          showUnderlineOnHover
                        >
                          {`${owner.name}${strategy.owners.length - 1 === index ? ' ' : ', '}`}
                        </Link>
                      ))}
                      {Boolean(strategy.link.length) && (
                        <>
                          •{' '}
                          <Link
                            monochromatic
                            to={strategy.link}
                            onClick={(e) => e.stopPropagation()}
                          >
                            View doc
                          </Link>
                        </>
                      )}
                    </Text>
                  }
                />
                <Box display="flex" style={{gap: 4}}>
                  {data?.map((goal) => (
                    <Tooltip
                      title={
                        <Box display="flex" alignItems="center" style={{gap: 4}}>
                          <UIIcon
                            size="small"
                            src={ObjectiveLine}
                            className={atoms({color: 'Text Subtle'})}
                          />
                          <Box
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '232px',
                            }}
                          >
                            {goal.title}
                          </Box>
                        </Box>
                      }
                      key={goal.id}
                    >
                      <Box>
                        <Link to={`/goals/${goal.id}`} hasNoUnderline>
                          <StatusButtonIcon
                            status={goal.updates?.[goal.updates.length - 1]?.status}
                            size="small"
                          />
                        </Link>
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            </Link>
          </Split.Item>
          {!isSubStrategry && (
            <Split.Item width="hug">
              <Tooltip title={t('Pillar+1 narratives')} placement="left-start" isPortaled={false}>
                <IconButton
                  variant="outline"
                  onClick={() => setShowSubStrategies(!showSubStrategies)}
                  className={atoms({visibility: !strategy.subStrategies ? 'hidden' : 'visible'})}
                >
                  <UIIcon src={showSubStrategies ? ChevronDownLine : ChevronRightLine} />
                </IconButton>
              </Tooltip>
            </Split.Item>
          )}
        </Split>
      </Box>
      {showSubStrategies && strategy.subStrategies?.length && (
        <Stack gap="8">
          {strategy.subStrategies.map((subStrategy) => (
            <StrategyBox
              key={subStrategy.id}
              timeframe={timeframe}
              strategy={subStrategy}
              isSubStrategry
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const StrategyContainer = ({
  strategies,
  timeframe,
}: {
  strategies: StrategyItem[];

  timeframe: string;
}) => {
  const [column1, column2] = strategies.reduce(
    (columns, item, index) => {
      columns[index % 2].push(item);
      return columns;
    },
    [[] as StrategyItem[], [] as StrategyItem[]]
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.column}>
        {column1.map((item) => (
          <StrategyBox key={item.id} timeframe={timeframe} strategy={item} />
        ))}
      </Box>
      <Box className={styles.column}>
        {column2.map((item) => (
          <StrategyBox key={item.id} timeframe={timeframe} strategy={item} />
        ))}
      </Box>
    </Box>
  );
};

export const StrategyOverview = ({
  timeframe,
  strategy,
}: {
  timeframe: string;
  strategy: StrategyItem[];
}) => {
  useDocumentTitle(t('strategy_plural'));

  // group strategy by .companyStrategy tru and false first
  const groupedStrategies = strategy
    .filter(({hidden}) => !hidden)
    .reduce(
      (grouped, item) => {
        grouped[item.companyStrategy ? 0 : 1].push(item);
        return grouped;
      },
      [[] as StrategyItem[], [] as StrategyItem[]]
    );

  return (
    <Stack gap="32">
      <Stack gap="16">
        <Title size="small">
          Company narratives (Top 3 pillars){' '}
          <Text size="small" color="faint">
            <Link
              monochromatic
              to={
                'https://www.dropbox.com/scl/fi/heqjozdee8wxgvpqrsrcz/2025-Company-Strategy-Company.paper?rlkey=nil9lq0qma0qpe73kjpi90xoj&st=ehib3b8c&dl=0'
              }
            >
              View doc
            </Link>
          </Text>
        </Title>
        <StrategyContainer strategies={groupedStrategies[0]} timeframe={timeframe} />
      </Stack>
      <Stack gap="16">
        <Title size="small">Other pillar narratives</Title>
        <StrategyContainer strategies={groupedStrategies[1]} timeframe={timeframe} />
      </Stack>
    </Stack>
  );
};
