import {Button} from '@dropbox/dig-components/dist/buttons';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, SearchLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee, EmployeeWithDelegates} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {ProfileAvatarName} from 'components/shared/ProfileAvatarName';
import {isSuperUser, sortEmployees} from 'helpers/utils';
import {useSearch} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {useCallback, useEffect, useState} from 'react';

import styles from './Delegates.module.css';
import {useDelegateService} from './hooks';

export const Delegates = () => {
  const {employees, updateEmployeeDelegate} = useDelegateService();

  const user = useAtomValue(pulseUserAtom);

  if (!user || (user && !isSuperUser(user.email))) {
    return null;
  }

  return (
    <ProjectContributorEditor
      employees={employees}
      updateDelegate={(employee) => updateEmployeeDelegate({employee})}
    />
  );
};

const renderTypeaheadRow = (employee: Employee) => {
  return (
    <Typeahead.Row
      key={employee.user_id}
      className={styles.typeaheadRow}
      value={employee.ldap}
      withTitle={<Text isBold>{employee.name}</Text>}
      withSubtitle={
        <Text size="small" color="faint">
          {employee.role}
        </Text>
      }
      withLeftAccessory={<Avatar user={employee} />}
    />
  );
};

const EmployeeSearch = ({
  isInvalid,
  autoFocus,
  onSelection,
}: {
  isInvalid?: boolean;
  autoFocus?: boolean;
  onSelection: (employee: Employee) => void;
}) => {
  const {directReports} = useAtomValue(loggedInEmployeeAtom);
  const [userInputValue, setUserInputValue] = useState('');
  const searchResult = useSearch({input: userInputValue});

  const employeeHints = searchResult.length ? searchResult : directReports;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (ldap: string) => {
    const selectedEmployee = employeeHints.find((e) => e.ldap === ldap);
    if (selectedEmployee) {
      onSelection(selectedEmployee);
      setUserInputValue('');
    }
  };

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            isInvalid={isInvalid}
            placeholder="Employee name"
            autoFocus={autoFocus}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withRightAccessory={
              <Box as={UIIcon} src={SearchLine} color="Text Subtle" marginTop="16" />
            }
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={50}
              results={employeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

const emptyContributor = {
  name: '',
  enable_delegation: true,
  delegates: [],
} as any as EmployeeWithDelegates;

export const ProjectContributorEditor = ({
  employees = [],
  updateDelegate,
}: {
  employees?: EmployeeWithDelegates[];
  updateDelegate: (employee: EmployeeWithDelegates) => void;
}) => {
  const [staged, stagedContributors] = useState<EmployeeWithDelegates[]>(employees);
  const addEmployee = useCallback(
    () => stagedContributors([...staged, emptyContributor]),
    [staged, stagedContributors]
  );

  const handleUpdateContributors = async (
    index: number,
    updatedContributor: EmployeeWithDelegates
  ) => {
    stagedContributors([...staged.slice(0, index), updatedContributor, ...staged.slice(index + 1)]);
  };

  useEffect(() => {
    stagedContributors(employees);
  }, [employees]);

  return (
    <Layout.Container>
      <Box as={Stack} gap="8">
        {staged.sort(sortEmployees).map((employee, index) => (
          <Split key={index} className={styles.projectLink}>
            <Box style={{minWidth: 250}}>
              {employee.name ? (
                <Box marginLeft="16" marginTop="12">
                  <ProfileAvatarName user={employee} detail={employee.level} source="delegation" />
                </Box>
              ) : (
                <EmployeeSearch
                  autoFocus={index > 0}
                  onSelection={(selectedEmployee) =>
                    handleUpdateContributors(index, {
                      ...selectedEmployee,
                      enable_delegation: true,
                      delegates: [],
                    })
                  }
                />
              )}
            </Box>

            <Box as={Split.Item} width="100%" marginLeft="12" paddingLeft="12">
              <PeopleSearchMenu
                placeholderText="Delegates..."
                selectedEmployees={employee.delegates}
                allowSelf
                onSelectEmployee={(selectedEmployee) =>
                  employee.name &&
                  updateDelegate({
                    ...employee,
                    enable_delegation: true,
                    delegates: [...employee.delegates, selectedEmployee],
                  })
                }
                onSelectEmployees={(selectedEmployees) =>
                  employee.name &&
                  updateDelegate({
                    ...employee,
                    enable_delegation: true,
                    delegates: [...employee.delegates, ...selectedEmployees],
                  })
                }
                onRemoveEmployee={() =>
                  employee.name &&
                  updateDelegate({
                    ...employee,
                    enable_delegation: true,
                    delegates: employee.delegates.slice(0, -1),
                  })
                }
              />
            </Box>
            <Box margin="16">
              <Toggle
                onClick={() =>
                  updateDelegate({
                    ...employee,
                    enable_delegation: !employee.enable_delegation,
                  })
                }
                checked={employee.enable_delegation}
              />
            </Box>
          </Split>
        ))}
        <Button
          style={{marginLeft: '-8px'}}
          onClick={addEmployee}
          size="small"
          variant="borderless"
          withIconStart={<UIIcon color="var(--dig-color__text__subtle)" src={AddLine} />}
        >
          <Text color="faint">Add delegation</Text>
        </Button>
      </Box>
    </Layout.Container>
  );
};
