import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Table as DIGTable} from '@dropbox/dig-components/dist/table';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {
  atoms,
  Box,
  Split,
  Stack,
  ThemeContainer,
  ThemeProvider,
  useTheme,
} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine, MoreHorizontalLine, SearchLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import cx from 'classnames';
import {Workstream, WorkstreamUpdate} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Layout} from 'components/DSYS/Layout';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {getStatusStyle, StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {Header} from 'components/shared/table/Header';
import {LabelGroupCell, LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {ColumnConfig, useColumnResize} from 'components/shared/table/useColumnResize';
import {calculateTimeAgo} from 'components/shared/TimeAgo';
import {isSuperUser} from 'helpers/utils';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useTracker} from './hooks';
import styles from './TrackerWorkstream.module.css';
import {WorkstreamComments} from './WorkstreamComments';

const toShortDate = (date: Date) =>
  date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});

const UpdateGraph = ({id, start_date, end_date, updates}: Workstream) => {
  const {mode} = useTheme();
  const timeoutRef = useRef<number | null>(null);

  const [focusedNode, setFocusedNode] = useState({
    left: 0,
    top: 0,
    content: '',
  });

  const {startDate, weeks} = useMemo(() => {
    const start = new Date(start_date);
    const end = new Date(end_date ?? new Date());

    return {
      startDate: start,
      endDate: end,
      weeks: Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 7)),
    };
  }, [start_date, end_date]);

  // Preprocess updates to map them by week
  const updatesByWeek = useMemo(() => {
    if (!updates) return new Map<number, WorkstreamUpdate>();

    const map = new Map<number, WorkstreamUpdate>();
    updates.forEach((update) => {
      const updateDate = new Date(update.created_at);
      const updateWeek = Math.floor(
        (updateDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7)
      );

      if (updateWeek > 0 && updateWeek <= weeks) {
        map.set(updateWeek, update);
      }
    });
    return map;
  }, [updates, startDate, weeks]);

  return (
    <Box display="flex" flexWrap="wrap" style={{gap: 4}} position="relative">
      {focusedNode.content && (
        <ThemeProvider mode={mode === 'bright' ? 'dark' : 'bright'}>
          <ThemeContainer>
            <Box
              display="flex"
              flexDirection="column"
              position="absolute"
              style={{zIndex: 2, ...focusedNode}}
            >
              <Box
                pointerEvents="none"
                backgroundColor="Background Raised"
                color="Text Base"
                borderRadius="XSmall"
                paddingX="12"
                paddingY="8"
                boxShadow="Raised"
              >
                <Text size="small">{focusedNode.content}</Text>
              </Box>
              <Box
                alignItems="center"
                backgroundColor="Background Raised"
                boxShadow="Raised"
                display="flex"
                pointerEvents="none"
                flexDirection="column"
                position="absolute"
                style={{
                  zIndex: 1,
                  width: 12,
                  height: 12,
                  bottom: -6,
                  left: 12,
                  transform: 'rotate(45deg)',
                }}
              />
            </Box>
          </ThemeContainer>
        </ThemeProvider>
      )}
      {Array.from({length: weeks}).map((_, index) => {
        const week = index + 1;
        const update = updatesByWeek.get(week);
        const weekDate = new Date(startDate.getTime() + week * 7 * 24 * 60 * 60 * 1000);
        const futureWeek = weekDate > new Date();
        const backgroundColor = getStatusStyle(mode, update?.status).color;

        return (
          <Box
            key={`${id}-${week}`}
            onMouseOut={() => {
              timeoutRef.current = Number(
                setTimeout(() => setFocusedNode({left: 0, top: 0, content: ''}), 300)
              );
            }}
            onMouseOver={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const parent = e.currentTarget.parentElement?.getBoundingClientRect();

              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }

              setFocusedNode({
                left: rect.left - (parent?.left ?? 0) - 12,
                top: rect.top - (parent?.top ?? 0) - 48,
                content: update
                  ? `${new Date(update.created_at).toLocaleDateString()} — ${t(update.status)}`
                  : `${weekDate.toLocaleDateString()}${futureWeek ? '' : ' — No update'}`,
              });
            }}
            display="block"
            borderStyle="Solid"
            borderWidth="1"
            borderColor={
              !futureWeek && backgroundColor === '#BBB5AE' ? 'Border Subtle' : 'transparent'
            }
            style={{
              borderRadius: 3,
              width: 12,
              height: 12,

              backgroundColor: futureWeek
                ? '#F7F5F2'
                : backgroundColor === '#BBB5AE'
                  ? 'transparent'
                  : backgroundColor,
            }}
          />
        );
      })}
    </Box>
  );
};

const DSYSTableRow = (workstream: Workstream & {columnConfigs: ColumnConfig[]}) => {
  const {name, employee_associations, end_date, done, updates, columnConfigs} = workstream;

  const latest2Updates = updates?.slice(-2).reverse();

  return (
    <DIGTable.Row>
      <LabelGroupCell
        verticalAlign="top"
        isBold
        width={columnConfigs[0].width}
        text={name}
        subText={
          <Stack gap="8">
            <Text color="subtle" size="small">
              Estimated delivery on <b>{end_date ? toShortDate(new Date(end_date)) : 'TBD'}</b>
            </Text>

            <UpdateGraph {...workstream} />
          </Stack>
        }
      />
      <DIGTable.Cell verticalAlign="top" width={columnConfigs[1].width}>
        <Box paddingY="16">
          <Tooltip title={employee_associations[0]?.employee.name}>
            <Avatar
              user={employee_associations[0]?.employee}
              size="small"
              style={{display: 'inline-block'}}
            />
          </Tooltip>
        </Box>
      </DIGTable.Cell>

      <LabelGroupSubtleCell
        verticalAlign="top"
        text={latest2Updates?.map((update) => (
          <Text
            key={update.id}
            size="small"
            className={atoms({
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
            })}
          >
            <Text
              color="subtle"
              size="small"
              className={atoms({flexShrink: 0})}
              style={{width: 52}}
            >
              {toShortDate(new Date(update.created_at))}
            </Text>

            <Box
              className={cx(
                styles.update,
                atoms({
                  borderRadius: 'Medium',
                  paddingLeft: '2',
                  marginBottom: '2',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                })
              )}
            >
              <Text
                size="small"
                className={atoms({display: 'flex', alignItems: 'flex-start', flexDirection: 'row'})}
              >
                <Box marginTop="2" paddingRight="4">
                  <StatusButtonIcon status={update.status} size="small" />
                </Box>

                <WorkstreamComments text={update.comment} />
              </Text>
            </Box>
          </Text>
        ))}
      />
      <DIGTable.Cell verticalAlign="top" width={columnConfigs[1].width}>
        <Box
          className={cx(
            styles.update,
            atoms({
              display: 'block',
              paddingX: '4',
              paddingY: '2',
              marginY: '12',
              borderRadius: 'Medium',
            })
          )}
        >
          <Text size="small">
            <RichTextArea theme="xsmall" value={done} />
          </Text>
        </Box>
      </DIGTable.Cell>
    </DIGTable.Row>
  );
};

const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'workstream', width: 250, minWidth: 250},
    {type: 'dri', width: 24, fixed: true},
    {type: 'updates', width: 396},
    {type: 'definition of done', short: 'done', width: 300},
  ];
};

export const TrackerWorkstream = ({reports}: {reports?: boolean}) => {
  const params = useParams() as {id: number | string};

  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);

  const isUserTracker = isNaN(Number(params.id));

  const {employee} = useEmployee({ldap: isUserTracker ? (params.id as string) : undefined});
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());
  const tableWidth = 270 + columnConfigs.reduce((acc, {width}) => acc + width, 0);

  const {data: tracker, isLoading} = useTracker(params.id, reports);

  const user = useAtomValue(pulseUserAtom);
  if (!user || (user && !isSuperUser(user.email))) {
    return null;
  }

  // last updated update across all tracker.workstreams.updates
  const lastUpdated = tracker?.workstreams.reduce(
    (acc, {workstream}) => {
      const lastUpdate = workstream.updates?.slice(-1)[0];
      if (lastUpdate && new Date(lastUpdate.created_at) > new Date(acc.created_at)) {
        return lastUpdate;
      }
      return acc;
    },
    {created_at: '1970-01-01T00:00:00'}
  );

  return (
    <>
      <Layout.Container
        breadcrumb={[
          {children: t('workstreams'), to: '/workstreams'},
          {children: tracker?.name ?? '', to: `/workstreams/${params.id}`},
          ...(reports ? [{children: t('reports'), to: `/workstreams/${params.id}/reports`}] : []),
        ]}
      >
        <Stack gap="16">
          <Split gap="8">
            <Split.Item width="fill">
              <LabelGroup
                align="top"
                withLeftAccessory={isUserTracker && employee && <Avatar user={employee} />}
                withText={
                  <Title size={24}>
                    {isLoading ? (
                      <Skeleton.Box height={28} width={288} />
                    ) : isUserTracker ? (
                      employee?.name + (reports ? ` (${t('reports')})` : '')
                    ) : (
                      tracker?.name
                    )}
                  </Title>
                }
                withSubtext={
                  isLoading ? (
                    <Skeleton.Box height={15} width={200} />
                  ) : isUserTracker ? (
                    employee?.role
                  ) : (
                    <>
                      Last updated{' '}
                      {calculateTimeAgo(
                        new Date(lastUpdated?.created_at ?? new Date()).toISOString() ?? ''
                      )}
                    </>
                  )
                }
              />
            </Split.Item>
            {loggedInEmployee.ldap === params.id && (
              <Split.Item>
                <Button variant="primary">Update all</Button>
              </Split.Item>
            )}
            <Split.Item>
              <Button variant="outline" withIconStart={<UIIcon size="small" src={CommentLine} />}>
                {t('workstream_comments')}
              </Button>
            </Split.Item>
            {isNaN(Number(params.id)) && (
              <Split.Item>
                <IconButton variant="outline">
                  <UIIcon size="small" src={MoreHorizontalLine} />
                </IconButton>
              </Split.Item>
            )}
          </Split>
          <Split>
            <Split.Item width="fill">
              <Title size={20}>
                {isLoading
                  ? ' '
                  : t('workstreams_count', {count: tracker?.workstreams?.length ?? 0})}
              </Title>
            </Split.Item>
            <Split.Item>
              {['at_risk', 'off_track', 'on_track'].map((status) => (
                <Chip key={status} size="small" className={atoms({marginLeft: '8'})}>
                  <Chip.IconAccessory>
                    <StatusButtonIcon status={status} />
                  </Chip.IconAccessory>
                  <Chip.Content>
                    <Text size="small">{t(status)}</Text>
                  </Chip.Content>
                </Chip>
              ))}
              <IconButton variant="transparent" className={atoms({marginLeft: '8'})}>
                <UIIcon src={SearchLine} />
              </IconButton>
            </Split.Item>
          </Split>
        </Stack>
      </Layout.Container>

      <Box
        as="div"
        paddingX="24"
        paddingY="20"
        marginTop="8"
        borderRadius="Medium"
        borderColor="Border Subtle"
        borderStyle="Solid"
        borderWidth="1"
        maxWidth="100%"
        marginX="auto"
        style={{width: tableWidth, overflowX: 'auto'}}
      >
        <DIGTable hasDividers={false} spacing="small" verticalAlign="center">
          <Header
            columnConfigs={columnConfigs}
            dragging={dragging}
            getMouseDownHandler={getMouseDownHandler}
          />
          <DIGTable.Body>
            {tracker?.workstreams
              ?.sort((a, b) => a.order - b.order)
              .map(({workstream}) => (
                <DSYSTableRow
                  key={workstream.id.toString()}
                  columnConfigs={columnConfigs}
                  {...workstream}
                />
              ))}
          </DIGTable.Body>
        </DIGTable>
      </Box>
    </>
  );
};
