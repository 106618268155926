import {Table as DIGTable} from '@dropbox/dig-components/dist/table';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {PuzzlePiecesMini} from '@dropbox/dig-illustrations';
import {Goal, GoalUser, KeyResult} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmptyState} from 'components/DSYS/EmptyState';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {Header} from 'components/shared/table/Header';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {KeyResultLine, ObjectiveLine} from 'views/goals_v2/icons';
import {parseUpdateComment} from 'views/goals_v2/Table/utils';
import {DrawerGoalData} from 'views/goals_v2/types';

import {Filters} from './Filter';
import styles from './Overview.module.css';

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'goal', width: 648, paddingLeft: '6', paddingRight: '0', minWidth: 300},
    {type: 'update', width: 520, paddingLeft: '6', paddingRight: '0', minWidth: 300},
    {type: 'DRI', width: 24, paddingLeft: '6', paddingRight: '0', fixed: true},
  ];
};

const isKeyResult = (data: Goal | KeyResult): data is KeyResult => {
  return (data as KeyResult).goal_id !== undefined;
};

const UpdateCell = ({maxWidth, updates}: {maxWidth: number; updates: Goal['updates']}) => {
  if (!updates || updates.length === 0) {
    return <EmptyCell />;
  }

  const updatedText = parseUpdateComment(updates[updates.length - 1].comment);
  const isAutoUpdate = updates[updates.length - 1].comment === 'automatic_objective_status_change';

  return (
    <DIGTable.Cell>
      <Box
        padding="4"
        paddingLeft="0"
        overflow="hidden"
        whiteSpace="nowrap"
        style={{
          maxWidth,
          textOverflow: 'ellipsis',
        }}
      >
        <TimeAgo timestamp={updates[updates.length - 1].updated_at} />

        <Text color={isAutoUpdate ? 'subtle' : 'standard'} className={atoms({marginLeft: '16'})}>
          {isAutoUpdate ? `Updated to ${t(updates[updates.length - 1].status)}` : updatedText}
        </Text>
      </Box>
    </DIGTable.Cell>
  );
};

const OKRTableRow = ({
  row,
  owner,
  privacies,
  columnConfigs,
  onClick,
}: {
  row: Goal | KeyResult;
  owner: GoalUser;
  privacies?: string;
  columnConfigs: ColumnConfig[];
  onClick: (data: DrawerGoalData) => void;
}) => {
  const isKR = isKeyResult(row);
  const paddingLeftKR = isKR ? '24' : '0';

  return (
    <>
      <DIGTable.Row
        isSelectable
        onClick={() =>
          onClick({goalId: isKR ? row.goal_id : row.id, keyResultId: isKR ? row.id : undefined})
        }
      >
        <DIGTable.Cell>
          <Box
            padding="4"
            display="flex"
            alignItems="center"
            className={atoms({marginLeft: paddingLeftKR})}
            style={{gap: 8}}
          >
            <StatusButtonIcon size="small" status={row.updates?.[row.updates.length - 1]?.status} />
            <UIIcon
              size="small"
              src={isKR ? KeyResultLine : ObjectiveLine}
              className={atoms({color: 'Text Subtle'})}
            />
            {privacies && (
              <Tooltip title={privacies}>
                <Box display="flex" alignItems="center">
                  <UIIcon size="small" src={LockLine} className={atoms({color: 'Text Subtle'})} />
                </Box>
              </Tooltip>
            )}
            <Box
              fontWeight={isKR ? undefined : 'Strong'}
              style={{
                maxWidth: columnConfigs[0].width - parseInt(paddingLeftKR, 10) - 64,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration:
                  row.updates?.[row.updates.length - 1]?.status === 'cancelled'
                    ? 'line-through'
                    : 'none',
              }}
            >
              {row.title}
            </Box>
          </Box>
        </DIGTable.Cell>

        <UpdateCell maxWidth={columnConfigs[1].width} updates={row.updates} />

        <DIGTable.Cell>
          <Tooltip title={owner.display_name} placement="right">
            <Box>
              <Avatar
                size="small"
                user={
                  isKR
                    ? row.contributors?.length
                      ? row.contributors?.[0]
                      : {ldap: owner.email.split('@')[0], name: owner.display_name}
                    : {ldap: row.users![0].email.split('@')[0], name: row.users![0].display_name}
                }
                style={{alignSelf: 'flex-end'}}
              />
            </Box>
          </Tooltip>
        </DIGTable.Cell>
      </DIGTable.Row>
      {!isKR &&
        row.key_results.map((kr) => (
          <OKRTableRow
            key={kr.id}
            columnConfigs={columnConfigs}
            row={kr}
            onClick={onClick}
            owner={owner}
          />
        ))}
    </>
  );
};

const filterTable = (filter: Filters) => (data: Goal) => {
  let show = true;

  if (filter.status.length) {
    if (!filter.status.includes(data.updates?.[data.updates.length - 1]?.status ?? 'no_status')) {
      show = false;
    }

    if (filter.status.includes('no_status') && show) {
      show = true;
    }
  }

  return show;
};

export const Table = ({
  data,
  filter,
  columnConfigs,
  dragging,
  onColumnDrag,
  setSelectedGoal,
}: {
  data: Goal[];
  filter: Filters;
  columnConfigs: ColumnConfig[];
  dragging?: string;
  onColumnDrag: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setSelectedGoal: (data: DrawerGoalData) => void;
}) => {
  const tableWidth = 48 + columnConfigs.reduce((acc, {width}) => acc + width, 0);

  const filteredData = data.filter(filterTable(filter));

  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      backgroundColor="Background Base"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: tableWidth, overflowX: 'auto'}}
    >
      {!filteredData?.length ? (
        <EmptyState
          title={t('no_others_goals_defined')}
          body={t('no_others_goals_defined_subtitle')}
          hideBorder
          image={
            <Box className={styles.puzzlePieces}>
              <PuzzlePiecesMini altText="puzzle pieces" width={64} height={64} />
            </Box>
          }
        />
      ) : (
        <DIGTable hasDividers={false} spacing="small" verticalAlign="center">
          <Header
            columnConfigs={columnConfigs}
            dragging={dragging}
            getMouseDownHandler={onColumnDrag}
          />
          <DIGTable.Body>
            <Box as="div" style={{height: 8}} />
            {filteredData.map((row) => {
              const owner = row.users![0];
              const privacies =
                row.is_custom_privacy_included || row.private
                  ? [
                      ...(row.individual_privacies?.map((e) => e.name) ?? []),
                      ...(row.team_privacies?.map((e) => e.name) ?? []),
                      t('owner_reporting_line'),
                    ].join(', ')
                  : undefined;
              return (
                <OKRTableRow
                  key={row.id}
                  columnConfigs={columnConfigs}
                  owner={owner}
                  privacies={privacies}
                  row={row}
                  onClick={setSelectedGoal}
                />
              );
            })}
          </DIGTable.Body>
        </DIGTable>
      )}
    </Box>
  );
};
