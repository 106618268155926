import {useMutation, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {
  Employee,
  Project,
  ProjectCreate,
  ProjectData,
  ProjectEdit,
  ProjectService,
  ProjectUpdateCreate,
} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

// export const useProjectsSuspense = () =>
//   useSuspenseQuery({
//     queryKey: ['projects'],
//     queryFn: () => getService(ProjectService).getApiV1ProjectsGet(),
//   });

export const useEpicTickets = ({id, enabled}: {id: number; enabled: boolean}) => {
  const {data, isLoading} = useQuery({
    enabled,
    queryKey: ['project', 'epic', id],
    queryFn: () => getService(ProjectService).getTicketsApiV1ProjectsTicketsIdGet(id),
  });
  return {tickets: data, isLoading};
};

export const useProject = ({id, timeframe}: {id: string | number; timeframe?: string}) =>
  useQuery({
    queryKey: ['project', id, timeframe],
    queryFn: () =>
      getService(ProjectService).getApiV1ProjectsIdentifierGet(id.toString(), timeframe),
  });

export const useProjectDetails = ({id}: {id: number}) =>
  useQuery({
    queryKey: ['project', 'details', id],
    queryFn: () => getService(ProjectService).projectDetailsApiV1ProjectsProjectIdDetailsGet(id),
  });

export const useProjectUpdates = ({id}: {id: number}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['project', 'updates', id],
    queryFn: () => getService(ProjectService).projectUpdatesApiV1ProjectsProjectIdUpdatesGet(id),
  });

  return {updates: data, isLoading};
};

export const useProjectUpdatesSuspense = ({id}: {id: number}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['project', 'updates', id],
    queryFn: () => getService(ProjectService).projectUpdatesApiV1ProjectsProjectIdUpdatesGet(id),
  });

  return {updates: data};
};

export const useProjectDelete = (employee: Employee) => {
  const {mutateAsync: deleteProject, isPending} = useMutation({
    mutationFn: ({data}: {data: Project}) =>
      getService(ProjectService).deleteApiV1ProjectsProjectIdDelete(data.id, employee.user_id),
    onSettled: (_response, error, {data}) => {
      if (error) {
        return;
      }

      const handleDelete = (old: ProjectData | undefined) => {
        if (!old?.projects) {
          return;
        }

        return Object.assign({}, old, {
          projects: old.projects.filter((project) => project.id !== data.id),
        });
      };

      queryClient.setQueryData(['projects', data.team?.slug], handleDelete);
      queryClient.setQueryData(['projects', undefined], handleDelete);
    },
  });

  return {deleteProject, isPending};
};

export const useCreateProjectUpdate = () => {
  const {mutateAsync: addUpdate} = useMutation({
    mutationFn: ({userId, data}: {userId: string; data: ProjectUpdateCreate}) =>
      getService(ProjectService).createUpdateApiV1ProjectsUpdatePost(userId, data),
    onSettled: (response, _error, {data}) => {
      if (!response) {
        return;
      }

      const handleProjectUpdate = (old: ProjectData | undefined) => {
        if (!old?.projects) {
          return;
        }

        return Object.assign({}, old, {
          projects: old.projects.map((project) => {
            if (project.id === data.project_id) {
              return Object.assign({}, project, {
                latest_update: response,
              });
            }
            return project;
          }),
        });
      };

      queryClient.setQueryData(['projects', undefined], handleProjectUpdate);

      // const teamSlug = queryClient.getQueryData<ProjectData>(['project', data.project_id])?.team
      //   ?.slug;

      // if (teamSlug) {
      //   queryClient.setQueryData(['projects', teamSlug], handleProjectUpdate);
      // }

      queryClient.setQueryData(['project', data.project_id], (old?: ProjectData) => {
        if (!old) {
          queryClient.invalidateQueries({queryKey: ['project', data.project_id]});
          return;
        }

        return Object.assign({}, old, {
          project: Object.assign({}, old.project, {
            latest_update: response,
          }),
        });
      });

      queryClient.invalidateQueries({queryKey: ['project', 'updates', data.project_id]});
    },
  });

  return {addUpdate};
};

export const useProjectEdit = () => {
  const {mutateAsync: editProject, isPending} = useMutation({
    mutationFn: ({
      projectId,
      employeeId,
      data,
    }: {
      projectId: number;
      employeeId: string;
      data: ProjectEdit;
    }) => getService(ProjectService).editApiV1ProjectsProjectIdPut(projectId, employeeId, data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      if (response.error) {
        return response.error;
      }

      const handleEdit = (old: ProjectData | undefined) => {
        if (!old?.projects) {
          return;
        }

        return Object.assign({}, old, {
          projects: old.projects.map((project) => {
            if (project.id === response.project?.id) {
              return response.project;
            }
            return project;
          }),
        });
      };

      queryClient.setQueryData(['projects', undefined], handleEdit);

      // const teamSlug = queryClient.getQueryData<ProjectData>(['project', response.project!.id])
      //   ?.team?.slug;
      // if (teamSlug) {
      //   queryClient.setQueryData(['projects', teamSlug], handleEdit);
      // }

      queryClient.invalidateQueries({queryKey: ['project', response.project!.id]});
      queryClient.invalidateQueries({queryKey: ['project', 'details', response.project!.id]});
    },
  });

  return {editProject, isPending};
};

export const useProjectCreate = () => {
  const {mutateAsync: createProject, isPending} = useMutation({
    mutationFn: ({data}: {data: ProjectCreate}) =>
      getService(ProjectService).createApiV1ProjectsPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      if (response.error) {
        return response.error;
      }

      const handleCreate = (old: ProjectData | undefined) => {
        if (!old?.projects) {
          return;
        }

        return Object.assign({}, old, {
          projects: [...old.projects, response.project],
        });
      };

      queryClient.setQueryData(['projects', undefined], handleCreate);
      queryClient.setQueryData(['projects', response.project!.team!.slug], handleCreate);
      queryClient.setQueryData(['project', response.project!.id], response);
    },
  });

  return {createProject, isPending};
};

export const useProjectLinks = () => {
  const {mutateAsync: addLink} = useMutation({
    mutationFn: ({userId, data}: {userId: string; data: ProjectUpdateCreate}) =>
      getService(ProjectService).createUpdateApiV1ProjectsUpdatePost(userId, data),
    onSettled: (response, _error, {data}) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['project', 'details', data.project_id]});
    },
  });

  return {addLink};
};
