import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  BoxViewLine,
  BriefcaseLine,
  CompassLine,
  GratitudeLine,
  HomeLine,
  NewsLine,
  PersonMultipleLine,
  PulseLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import cx from 'classnames';
import {Link} from 'components/DSYS/Link';
import {ROUTE_PATHS} from 'constant';
import {isMomenticTestAccount, isSuperUser} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useLocation} from 'react-router-dom';

import styles from './LeftNav.module.css';
import {WorkstreamLine} from './WorkstreamLine';

export const LeftNav = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const email = pulseUser?.email;
  const {isPulseEnabled, isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);
  const isMobile = useAtomValue(isMobileAtom);
  const isStageTestAccount = isMomenticTestAccount(email);

  const navItems = [
    {
      text: t('home'),
      path: ROUTE_PATHS.HOME,
      icon: HomeLine,
    },
    {
      text: t('goals'),
      path: ROUTE_PATHS.GOALS,
      icon: TargetLine,
      state: {resetView: true},
      // Show /goals on stage for Momentic testing
      hidden: isDropboxOSEnabled && !isStageTestAccount,
    },
    {
      text: t('people'),
      path: ROUTE_PATHS.PEOPLE,
      icon: PersonMultipleLine,
    },
    {
      text: t('teams'),
      path: ROUTE_PATHS.TEAMS,
      icon: TeamLine,
    },
    {
      text: t('news'),
      path: ROUTE_PATHS.NEWS,
      icon: NewsLine,
      hidden: isStageTestAccount,
    },
    {
      text: t('projects'),
      path: ROUTE_PATHS.PROJECTS,
      icon: BriefcaseLine,
      hidden: isStageTestAccount || isDropboxOSEnabled,
    },
    {
      text: t('strategy_plural'),
      path: ROUTE_PATHS.STRATEGIES,
      icon: CompassLine,
      hidden: isStageTestAccount || isDropboxOSEnabled,
    },
    {
      text: t('dashboards'),
      path: ROUTE_PATHS.DASHBOARDS,
      icon: BoxViewLine,
      hidden: isStageTestAccount || isDropboxOSEnabled,
    },
    {
      text: t('pulse'),
      path: ROUTE_PATHS.PULSES,
      icon: PulseLine,
      hidden: !isPulseEnabled || isStageTestAccount || isDropboxOSEnabled,
    },
    {
      text: t('gratitude'),
      path: ROUTE_PATHS.GRATITUDE_BROWSE,
      icon: GratitudeLine,
    },
    {
      text: t('workstreams'),
      path: ROUTE_PATHS.WORKSTREAM_TRACKERS,
      icon: WorkstreamLine,
      hidden: !isSuperUser(email) || isStageTestAccount || isDropboxOSEnabled,
    },
  ].filter((item) => !item.hidden);

  return (
    <div
      className={cx(styles.collapsedLeftNav, {
        [styles.collapsedLeftNavDesktop]: !isMobile,
      })}
    >
      <Box as={Stack} gap="8">
        {navItems.map((props, key) => (
          <LinkItem key={key} {...props} />
        ))}
      </Box>
    </div>
  );
};

const LinkItem = ({
  onClick,
  text,
  path,
  icon,
  state,
}: {
  text: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  path: string;
  onClick?: () => void;
  extra?: React.ReactNode;
  state?: {[key: string]: any};
}) => {
  const location = useLocation();
  const isMobile = useAtomValue(isMobileAtom);

  const isActive =
    path === ROUTE_PATHS.HOME
      ? location.pathname === ROUTE_PATHS.HOME
      : location.pathname.startsWith(path);

  const linkContent = (
    <Box
      as={Link}
      // Link props
      to={path ?? location.pathname}
      onClick={onClick}
      state={{source: 'left-nav', ...state}}
      hasNoUnderline
      // Box props
      borderRadius={isMobile ? 'Circular' : 'Medium'}
      fontFamily="Text"
      fontSize="Text Medium"
      width="100%"
      paddingY="4"
      paddingRight="12"
      paddingLeft={isMobile ? '4' : '8'}
      display="flex"
      alignItems="center"
      backgroundColor={isActive ? 'Opacity Surface' : 'transparent'}
      {...withShade({
        className: cx(styles.itemLink, {
          [styles.activeItemLink]: isActive,
        }),
      })}
    >
      {isMobile ? (
        <Box as={UIIcon} flexShrink={0} src={icon} />
      ) : (
        <Box display="flex" alignItems="center">
          <Box as={UIIcon} flexShrink={0} isOpticallyAligned src={icon} />
          <Box marginLeft="10" fontWeight={isActive ? 'Strong' : 'Base'}>
            {text}
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <Tooltip title={text} placement="right">
          <div>{linkContent}</div>
        </Tooltip>
      ) : (
        linkContent
      )}
    </>
  );
};
