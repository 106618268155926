import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Table as DIGTable} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  ChevronDownLine,
  PersonMultipleLine,
  TeamLine,
  WarningLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {growthbookCacheAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import cx from 'classnames';
import {EmployeeWithReportTeams, TeamAssociation, ToolsService} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmployeeTeamTypeahead} from 'components/DSYS/EmployeeTeamTypeahead';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {SavableTeamListTypeahead} from 'components/DSYS/TeamListTypeahead';
import {Title} from 'components/DSYS/Title';
import {Header} from 'components/shared/table/Header';
import {LabelGroupCell} from 'components/shared/table/LabelGroupCell';
import {ColumnConfig, useColumnResize} from 'components/shared/table/useColumnResize';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {isEmployee, useEditTeams} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {NotFound} from 'pages/NotFound';
import {useEffect, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {Checkmark} from './Checkmark';
import styles from './CheckupTool.module.css';
import {ExportCSVButton} from './ExportCSVButton';
import {getCheckupError} from './util';

export const recentSearchAtom = atomWithStorage(
  'checkup-searches',
  [] as {label: string; type: string; id: string}[]
);

const EmployeeTeamSearch = ({uid, setUid}: {uid: string; setUid: (uid: string) => void}) => {
  const [recentSearches, setRecentSearches] = useAtom(recentSearchAtom);
  const {reportingLine, employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  const onSelect = (label: string, type: string, id: string) => {
    setUid(id);
    if (recentSearches.length >= 7) {
      recentSearches.length = 7;
    }
    setRecentSearches([{label, type, id}, ...recentSearches.filter((search) => search.id !== id)]);
  };

  return (
    <Menu.Wrapper>
      {({getTriggerProps, getContentProps, closeMenu}) => (
        <>
          <IconButton
            {...getTriggerProps({
              onKeyDown: (e) => e.stopPropagation(),
            })}
            variant="transparent"
          >
            <UIIcon src={ChevronDownLine} className={atoms({color: 'Text Subtle'})} />
          </IconButton>
          <Menu.Content {...getContentProps()} placement="right-start" style={{width: 490}}>
            <Box paddingX="16" paddingTop="16">
              <EmployeeTeamTypeahead
                selection={uid}
                onSelect={(label, type, id) => {
                  closeMenu({});
                  onSelect(label, type, id);
                }}
              />
            </Box>
            <Box
              paddingX="16"
              paddingY="4"
              display="flex"
              paddingBottom="8"
              flexDirection="row"
              style={{gap: 4, marginTop: -16}}
            >
              {employeeTeams?.[0] && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(employeeTeams[0].slug ?? '');
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={TeamLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{employeeTeams[0].name}</Box>
                </Chip>
              )}
              {employeeTeams?.[1] && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(employeeTeams[1].slug ?? '');
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={TeamLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{employeeTeams[1].name}</Box>
                </Chip>
              )}
              {reportingLine.length > 1 && (
                <Chip
                  onClick={() => {
                    closeMenu({});
                    setUid(reportingLine[1].ldap);
                  }}
                  size="small"
                >
                  <Chip.IconAccessory>
                    <UIIcon src={PersonMultipleLine} />
                  </Chip.IconAccessory>
                  <Box marginLeft="4">{reportingLine?.[1].name}</Box>
                </Chip>
              )}
            </Box>
            {Boolean(recentSearches.length) && (
              <Box
                paddingX="16"
                paddingBottom="16"
                borderTop="Solid"
                borderColor="Border Subtle"
                borderWidth="1"
              >
                <Menu.Segment withLabel={t('recent')}>
                  {recentSearches.map(({label, id}) => (
                    <Menu.ActionItem
                      key={id}
                      onClick={() => setUid(id)}
                      className={atoms({borderRadius: 'Small'})}
                    >
                      {label}
                    </Menu.ActionItem>
                  ))}
                </Menu.Segment>
              </Box>
            )}
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const CheckupTool = () => {
  const id = useLoaderData() as string;
  const {employee, directReports, reportingLine} = useAtomValue(loggedInEmployeeAtom);

  const {isExportEnabled} = useAtomValue(growthbookCacheAtom);
  const [employeeDrawer, setDrawerOpen] = useState<EmployeeWithReportTeams | undefined>(undefined);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const navigate = useNavigate();

  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());

  const {data, isLoading} = useQuery({
    queryKey: ['checkup', id],
    queryFn: () => getService(ToolsService).getTeamAuditApiV1ToolIdGet(id),
    enabled: Boolean(id),
  });

  const {editTeams, isPending} = useEditTeams({id, ldap: employeeDrawer?.ldap ?? ''});

  useDocumentTitle(data?.checkup.name ?? '');

  const handleSetId = (selected: string) => {
    setDrawerOpen(undefined);
    navigate(`/checkup/${selected}`);
  };

  const onCancel = () => {
    setDrawerOpen(undefined);
  };

  const onSave = async (selections: TeamAssociation[]) => {
    try {
      await editTeams({
        teams: selections.map((assoc) => ({
          team_id: assoc.team.team_id,
          slug: assoc.team.slug ?? '',
          allocation: assoc.allocation,
        })),
      });

      setSnackbarMessage({text: t('saved')});

      setDrawerOpen(undefined);
    } catch (e) {
      setSnackbarMessage({text: t('error_saving')});
    }
  };

  useEffect(() => {
    if (!id && employee && reportingLine?.length) {
      if (directReports.length) {
        navigate(`/checkup/${employee.ldap}`);
      } else if (reportingLine.length > 1) {
        navigate(`/checkup/${reportingLine[1].ldap}`);
      }
    }
  }, [directReports.length, employee, id, navigate, reportingLine]);

  if (id && !isLoading && !data) {
    return <NotFound />;
  }

  if (!id) {
    return null;
  }

  return (
    <Stack gap="16">
      <Layout.InlineDrawerContainer
        open={Boolean(employeeDrawer)}
        breadcrumb={
          data?.checkup.name
            ? [
                {
                  children: t('tools'),
                  to: '/checkup',
                },
                {
                  children: t('checkup'),
                  to: '/checkup',
                },
                {
                  children: data?.checkup.name,
                  to: `/checkup/${id}`,
                },
              ]
            : undefined
        }
        drawerHeader={<Title size={18}>{t('edit_teams')}</Title>}
        drawerIcon={TeamLine}
        drawerBody={
          employeeDrawer ? (
            <SavableTeamListTypeahead
              isPending={isPending}
              employee={employeeDrawer}
              memberSort={(a, b) => (a.team.name ?? '').localeCompare(b.team.name ?? '')}
              onSave={onSave}
              onCancel={onCancel}
              withRightAccessory={(assoc) => <Text>{assoc.allocation}%</Text>}
            />
          ) : null
        }
        onClose={() => setDrawerOpen(undefined)}
      >
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" style={{gap: 2}}>
            {isLoading ? (
              <Skeleton.Box width={120} height={32} className={atoms({marginRight: '4'})} />
            ) : (
              <Title className={atoms({marginRight: '4'})}>{data?.checkup.name}</Title>
            )}
            <Checkmark id={id} />
            <EmployeeTeamSearch uid={id} setUid={handleSetId} />
          </Box>

          {isExportEnabled && <ExportCSVButton />}
        </Box>
        <Text size="small" color="subtle">
          {isLoading ? (
            <Skeleton.Text width={200} />
          ) : isEmployee(data?.checkup) ? (
            t('direct_reports', {count: data?.checkup.direct_report_count ?? 0}) +
            ((data?.employees.length ?? 0) < (data?.checkup.direct_report_count ?? 0)
              ? ` ${t('direct_report_contractors', {count: (data?.checkup.direct_report_count ?? 0) - (data?.employees.length ?? 0)})}`
              : '') +
            ((data?.checkup.total_report_count ?? 0) !== (data?.checkup.direct_report_count ?? 0)
              ? ` • ${t('total_reports', {count: data?.checkup.total_report_count ?? 0})}`
              : '')
          ) : (
            t('member', {
              count: data?.checkup.total_employee_count,
              countString: data?.checkup.total_employee_count.toLocaleString(),
            }) +
            ((data?.employees.length ?? 0) < (data?.checkup.total_employee_count ?? 0)
              ? ` ${t('direct_report_contractors', {
                  count: (data?.checkup.total_employee_count ?? 0) - (data?.employees.length ?? 0),
                })}`
              : '') +
            (data?.checkup.subteam_count
              ? ` • ${t('sub_team', {count: data?.checkup.subteam_count})}`
              : '')
          )}
        </Text>
      </Layout.InlineDrawerContainer>

      <CheckupTable
        employees={data?.employees}
        columnConfigs={columnConfigs}
        dragging={dragging}
        onColumnDrag={getMouseDownHandler}
        onClick={setDrawerOpen}
      />
    </Stack>
  );
};

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 200},
    {type: 'status', width: 314},
    {type: 'allocation', short: 'alloc', width: 456},
  ];
};

const DSYSTableRow = ({
  onClick,
  columnConfigs,
  index,
  indexOfFirstError,
  indexOfFirstNonError,
  ...data
}: EmployeeWithReportTeams & {
  columnConfigs: ColumnConfig[];
  onClick: (employee: EmployeeWithReportTeams) => void;
  index: number;
  indexOfFirstError: number;
  indexOfFirstNonError: number;
}) => {
  const status = getCheckupError(data);

  const hasAllocation = data.team_associations.some((assoc) => assoc.allocation !== null);

  return (
    <Box
      as={DIGTable.Row}
      className={cx(atoms({backgroundColor: status ? 'Alert Surface' : undefined}), {
        [styles.error]: status,
        [styles.firstError]: index === indexOfFirstError,
        [styles.lastError]: index === indexOfFirstNonError - 1,
      })}
      isSelectable
      onClick={() => onClick(data)}
    >
      <LabelGroupCell
        isBold
        width={columnConfigs[0].width}
        text={data.name}
        className={atoms({paddingLeft: '16', paddingY: '12'})}
        subText={
          <>
            <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{data.role ?? ''}</span>
            {data.level ? <span style={{paddingLeft: 4}}>({data.level})</span> : ''}
          </>
        }
        withLeftAccessory={<Avatar user={data} />}
      />
      <LabelGroupCell
        className={atoms({paddingX: '16', paddingY: '12'})}
        text={
          status === 'checkup_reports_error' ? (
            <ButtonLink variant="transparent" to={`/checkup/${data.ldap}`} target="_blank">
              <Box display="flex" alignItems="center">
                {t('checkup_reports_error')}
              </Box>
            </ButtonLink>
          ) : (
            <Box display="flex">{status ? t(status) : undefined}</Box>
          )
        }
        withLeftAccessory={
          <UIIcon
            src={status ? WarningLine : CheckmarkLine}
            className={atoms({color: 'Text Subtle'})}
          />
        }
      />
      <LabelGroupCell
        className={atoms({paddingRight: '16', paddingY: '12'})}
        text={data.team_associations
          .map(
            ({team, allocation}) =>
              `${team.name ?? ''}${` (${allocation ?? (hasAllocation ? 0 : ((1 / data.team_associations.length) * 100).toFixed())}%)`}`
          )
          .sort()
          .join(', ')}
      />
    </Box>
  );
};

const checkupErrorSort = [
  'checkup_no_teams',
  'checkup_reports_error',
  'checkup_only_member',
  'checkup_allocation',
  'checkup_too_many_teams',
  '',
];
const sortTable = (a: EmployeeWithReportTeams, b: EmployeeWithReportTeams) => {
  const aError = getCheckupError(a);
  const bError = getCheckupError(b);

  if (aError !== bError) {
    return checkupErrorSort.indexOf(aError ?? '') - checkupErrorSort.indexOf(bError ?? '');
  }

  if (a.level !== b.level) {
    return (b.level ?? '').localeCompare(a.level ?? '');
  }

  return (a.name ?? '').localeCompare(b.name ?? '');
};

const CheckupTable = ({
  employees,
  columnConfigs,
  dragging,
  onColumnDrag,
  onClick,
}: {
  employees?: EmployeeWithReportTeams[];
  columnConfigs: ColumnConfig[];
  dragging?: string;
  onColumnDrag: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick: (employee: EmployeeWithReportTeams) => void;
}) => {
  const tableWidth = 270 + columnConfigs.reduce((acc, {width}) => acc + width, 0);

  const sortedTable = employees?.sort(sortTable);

  const indexOfFirstError = sortedTable?.findIndex((employee) => getCheckupError(employee));
  const indexOfFirstNonError = sortedTable?.findIndex((employee) => !getCheckupError(employee));

  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      backgroundColor="Background Base"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: tableWidth, overflowX: 'auto'}}
    >
      <DIGTable hasDividers={false} spacing="small" verticalAlign="center">
        <Header
          columnConfigs={columnConfigs}
          dragging={dragging}
          getMouseDownHandler={onColumnDrag}
        />
        <DIGTable.Body>
          <Box as="div" style={{height: 8}} />
          {!sortedTable ? (
            <DIGTable.Row style={{height: 680}} />
          ) : (
            sortedTable.map((row, index) => (
              <DSYSTableRow
                key={row.ldap}
                {...row}
                index={index}
                columnConfigs={columnConfigs}
                onClick={onClick}
                indexOfFirstError={indexOfFirstError!}
                indexOfFirstNonError={
                  indexOfFirstNonError! === -1 ? sortedTable.length : indexOfFirstNonError!
                }
              />
            ))
          )}
        </DIGTable.Body>
      </DIGTable>
    </Box>
  );
};
