import {DEFAULT_TIMEFRAME, ROUTE_PATHS, STEAM_TEAM_SLUG} from 'constant';
import {CardManagement} from 'pages/CardManagement';
import {CeleryTaskMetaAdmin} from 'pages/CeleryTaskMeta';
import {NewGoal} from 'pages/CreateGoal';
import {Dashboards} from 'pages/Dashboards';
import {EditGoal} from 'pages/EditGoal';
import {ErrorPage} from 'pages/ErrorPage';
import {Goals} from 'pages/Goals';
import {Grammarly} from 'pages/Grammarly';
import {Gratitude} from 'pages/Gratitude';
import {ManageBadges} from 'pages/ManageBadges';
import {News} from 'pages/News';
import {People} from 'pages/People';
import {Strategies} from 'pages/Strategies';
import {Teams} from 'pages/Teams';
import {Waitlist} from 'pages/Waitlist';
import {Suspense} from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  json,
  LoaderFunction,
  LoaderFunctionArgs,
  Navigate,
  Route,
} from 'react-router-dom';
import {getGoalService, getService, getTeamsService} from 'utilities';
import {NewsCreateView} from 'views/news/NewsCreateView';
import {NewsUpdateView} from 'views/news/NewsUpdateView';

import {
  EmployeeService,
  NewsService,
  ProjectService,
  PulseService,
  WorkstreamService,
} from './client';
import {TeamCreate} from './components/teams/TeamCreate';
import {TeamEdit} from './components/teams/TeamEdit';
import {Editor} from './pages/Editor';
import {Logout} from './pages/Logout';
import {NotFound} from './pages/NotFound';
import {Review} from './pages/Review';
import {Settings} from './pages/Settings';
import {Delegates} from './views/admin/Delegates';
import {ExternalApp} from './views/admin/ExternalApp';
import {NotificationScheduler} from './views/admin/NotificationScheduler';
import {PulseScheduler} from './views/admin/PulseScheduler';
import {DSYS} from './views/dsys/DSYS';
import {InboxOutboxView} from './views/gratitude/InboxOutboxView';
import {Home} from './views/home/Home';
import {LoggedInPage} from './views/LoggedInPage';
import {ProfileView} from './views/profile/ProfileView';
import {ProjectDigest} from './views/projects/ProjectDigest';
import {ProjectView} from './views/projects/ProjectView';
import {PulsesView} from './views/pulse/PulsesView';
import {PulseView} from './views/pulse/PulseView';
import {queryClient} from './views/QueryClientWrapper';
import {CheckupTool} from './views/tools/CheckupTool';
import {TrackerCreate} from './views/workstreams/TrackerCreate';
import {TrackerEditor} from './views/workstreams/TrackerEdit';
import {Trackers} from './views/workstreams/Trackers';
import {TrackerWorkstream} from './views/workstreams/TrackerWorkstream';
import {WorkstreamCreate} from './views/workstreams/WorkstreamCreate';
import {WorkstreamEditor} from './views/workstreams/WorkstreamEdit';

const profileLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  const ldap = params.userid || '';
  return queryClient
    .fetchQuery({
      queryKey: ['profile', ldap],
      queryFn: () => getService(EmployeeService).getProfileApiV1PeopleUserIdGet(ldap),
    })
    .then((response) => {
      return response;
    });
};

const goalDetailLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  const goalId = params.goalid ? Number(params.goalid) : undefined;
  return goalId
    ? queryClient
        .fetchQuery({
          queryKey: ['goal', goalId],
          queryFn: () => getGoalService().readGoalByIdApiV1GoalsGoalIdGet(goalId),
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.status === 404) {
            throw json(
              {
                error: 'Not Found',
              },
              {status: 404}
            );
          }
          return null;
        })
    : null;
};

const teamLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  const slug = params.slug ? params.slug : STEAM_TEAM_SLUG.toString();
  return queryClient
    .fetchQuery({
      queryKey: ['team', slug],
      queryFn: () => getTeamsService().getTeamBySlugApiV1TeamsSlugSlugGet(slug),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.status === 404) {
        throw json(
          {
            error: 'Not Found',
          },
          {status: 404}
        );
      }
      return null;
    });
};

const workstreamLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  try {
    const id = parseInt(params.id ?? '', 10);

    return queryClient
      .fetchQuery({
        queryKey: ['workstream', id],
        queryFn: () => getService(WorkstreamService).getApiV1WorkstreamIdGet(id),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.status === 404) {
          throw json(
            {
              error: 'Not Found',
            },
            {status: 404}
          );
        }
        return null;
      });
  } catch (error: any) {
    if (error.status === 403) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 403}
      );
    } else if (error.status === 404) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 404}
      );
    }
  }
};

const checkupLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  return params.id ? params.id : null;
};

const pulseLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  try {
    const slug = params.slug ?? 'people-team';
    if (params.id && params.slug) {
      const id = parseInt(params.id, 10);
      if (params.group) {
        return queryClient.fetchQuery({
          queryKey: ['pulse', params.group, id, slug],
          queryFn: () =>
            getService(PulseService).getPulseGroupApiV1PulseGroupIdPulseIdGet(
              params.group!,
              slug,
              id
            ),
        });
      } else {
        return queryClient.fetchQuery({
          queryKey: ['pulse', 'subteam', id, slug],
          queryFn: () =>
            getService(PulseService).getPulseGroupApiV1PulseGroupIdPulseIdGet('subteam', slug, id),
        });
      }
    }

    const pulseId = params.id ? parseInt(params.id, 10) : undefined;
    const response = await queryClient.fetchQuery({
      queryKey: ['pulse', pulseId],
      queryFn: () => getService(PulseService).latestApiV1PulseGet(pulseId),
    });

    return response;
  } catch (error: any) {
    if (error.status === 403) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 403}
      );
    } else if (error.status === 404) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 404}
      );
    }
  }
};

const projectLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  try {
    if (params.identifier && /^\d+$/.test(params.identifier)) {
      const id = parseInt(params.identifier, 10);

      const team = await queryClient.fetchQuery({
        queryKey: ['project', 'team', id],
        queryFn: () => getService(ProjectService).getApiV1ProjectsIdentifierTeamGet(id.toString()),
      });

      const data = Promise.all([
        queryClient.fetchQuery({
          queryKey: ['project', id],
          queryFn: () => getService(ProjectService).getApiV1ProjectsIdentifierGet(id.toString()),
        }),
        queryClient.fetchQuery({
          queryKey: ['project', 'details', id],
          queryFn: () =>
            getService(ProjectService).projectDetailsApiV1ProjectsProjectIdDetailsGet(id),
        }),
      ]).then((results) => Object.assign({}, ...results));

      return defer({data, team});
    }

    const team = await queryClient.fetchQuery({
      queryKey: ['project', 'team', params.identifier],
      queryFn: () =>
        getService(ProjectService).getApiV1ProjectsIdentifierTeamGet(
          params.identifier ?? 'undefined'
        ),
    });

    const data = queryClient
      .fetchQuery({
        queryKey: ['projects', params.identifier, DEFAULT_TIMEFRAME],
        queryFn: () =>
          getService(ProjectService).getApiV1ProjectsIdentifierGet(
            params.identifier ?? 'undefined',
            DEFAULT_TIMEFRAME
          ),
      })
      .then((response) => {
        // Populate project query keys to save an extra request
        response.projects?.map((project) =>
          queryClient.setQueryData(['project', project.id], {
            team: project.team,
            project,
          })
        );

        return response;
      });

    return defer({team, data});
  } catch (error: any) {
    if (error.status === 403) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 403}
      );
    } else if (error.status === 404) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 404}
      );
    }
  }
};

const newsLoader: LoaderFunction = async ({params}: LoaderFunctionArgs) => {
  try {
    if (params.identifier && /^\d+$/.test(params.identifier)) {
      const id = parseInt(params.identifier, 10);
      return await Promise.all([
        queryClient.fetchQuery({
          queryKey: ['news', 'latest'],
          queryFn: () => getService(NewsService).getPostsApiV1NewsGet(),
        }),
        queryClient.fetchQuery({
          queryKey: ['news', id],
          queryFn: () => getService(NewsService).getPostApiV1NewsIdGet(id),
        }),
      ]);
    } else if (params.identifier) {
      if (params.identifier === 'drafts') {
        return await Promise.all([
          queryClient.fetchQuery({
            queryKey: ['news', 'drafts'],
            queryFn: () => getService(NewsService).getDraftsApiV1NewsDraftsGet(),
          }),
        ]);
      }
      if (params.identifier === 'categories') {
        return await Promise.all([
          queryClient.fetchQuery({
            queryKey: ['news', 'categories'],
            queryFn: () => getService(NewsService).getCategoriesApiV1NewsCategoriesGet(),
          }),
        ]);
      }
      return await Promise.all([
        queryClient.fetchQuery({
          queryKey: ['news', 'category', params.identifier],
          queryFn: () =>
            getService(NewsService).getByCategoryApiV1NewsCategoryCategoryGet(
              params.identifier ?? ''
            ),
        }),
      ]);
    } else {
      return await Promise.all([
        queryClient.fetchQuery({
          queryKey: ['news', 'latest'],
          queryFn: () => getService(NewsService).getPostsApiV1NewsGet(),
        }),
      ]);
    }
  } catch (error: any) {
    if (error.status === 403) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 403}
      );
    } else if (error.status === 404) {
      throw json(
        {
          error: 'Not Found',
        },
        {status: 404}
      );
    }
    return [[]];
  }
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <Suspense fallback={null}>
            <LoggedInPage />
          </Suspense>
        }
        ErrorBoundary={ErrorPage}
      >
        <Route path="/login" element={<Navigate to={ROUTE_PATHS.HOME} replace />} />
        <Route
          path="/me"
          loader={profileLoader}
          element={<Navigate to={ROUTE_PATHS.PROFILE} replace />}
        />
        <Route path={ROUTE_PATHS.LOGOUT} element={<Logout />} />
        <Route path={ROUTE_PATHS.HOME} element={<Home />} />
        <Route path={ROUTE_PATHS.GOALS} element={<Goals />} />
        <Route path={ROUTE_PATHS.GOAL_DETAIL} loader={goalDetailLoader} element={<Goals />} />
        <Route path={ROUTE_PATHS.GOAL_KR_DETAIL} loader={goalDetailLoader} element={<Goals />} />
        <Route path={ROUTE_PATHS.NEW_GOAL} element={<NewGoal />} />
        <Route path="/goals/new" element={<Navigate to={ROUTE_PATHS.NEW_GOAL} />} />
        <Route path={ROUTE_PATHS.EDIT_GOAL} element={<EditGoal />} />
        <Route path={ROUTE_PATHS.PEOPLE} element={<People />} />
        <Route path={ROUTE_PATHS.PROFILE} loader={profileLoader} element={<ProfileView />} />
        <Route path={ROUTE_PATHS.TEAMS} loader={teamLoader} element={<Teams />} />
        <Route path={ROUTE_PATHS.TEAM} loader={teamLoader} element={<Teams />} />
        <Route path={ROUTE_PATHS.TEAM_EDIT} loader={teamLoader} element={<TeamEdit />} />
        <Route path={ROUTE_PATHS.TEAM_NEW} element={<TeamCreate />} />
        {/* <Route path={ROUTE_PATHS.PROJECT_EDIT} loader={projectLoader} element={<ProjectEdit />} /> */}
        {/* <Route path={ROUTE_PATHS.PROJECT_NEW} loader={projectLoader} element={<ProjectCreate />} /> */}
        <Route path={ROUTE_PATHS.CHECKUP} loader={checkupLoader} element={<CheckupTool />} />
        <Route path={ROUTE_PATHS.CHECKUP_ID} loader={checkupLoader} element={<CheckupTool />} />
        <Route path={ROUTE_PATHS.PROJECT} loader={projectLoader} element={<ProjectView />} />
        <Route path={ROUTE_PATHS.PROJECTS} loader={projectLoader} element={<ProjectView />} />
        <Route path={ROUTE_PATHS.PROJECT_DIGEST} element={<ProjectDigest />} />
        <Route path={ROUTE_PATHS.PULSE} loader={pulseLoader} element={<PulseView />} />
        <Route path={ROUTE_PATHS.PULSE_GROUP} loader={pulseLoader} element={<PulseView />} />
        <Route path={ROUTE_PATHS.PULSE_ID} loader={pulseLoader} element={<PulsesView />} />
        <Route path={ROUTE_PATHS.PULSES} loader={pulseLoader} element={<PulsesView />} />
        <Route path={ROUTE_PATHS.STRATEGIES} element={<Strategies />} />
        <Route path={ROUTE_PATHS.STRATEGIES_DETAIL} element={<Strategies />} />
        <Route path={ROUTE_PATHS.SETTINGS} element={<Settings />} />
        <Route path={ROUTE_PATHS.REVIEW} element={<Review />} />
        <Route path={ROUTE_PATHS.EDITOR} element={<Editor />} />
        <Route path={ROUTE_PATHS.GRAMMARLY} element={<Grammarly />} />
        <Route path={ROUTE_PATHS.GRATITUDE_BROWSE} element={<Gratitude />} />
        <Route path={ROUTE_PATHS.GRATITUDE_INBOX} element={<InboxOutboxView mailbox="inbox" />} />
        <Route path={ROUTE_PATHS.GRATITUDE_OUTBOX} element={<InboxOutboxView mailbox="outbox" />} />
        <Route path={ROUTE_PATHS.ADMIN_CELERY_TASKS} element={<CeleryTaskMetaAdmin />} />
        <Route path={ROUTE_PATHS.MANAGE_BADGES} element={<ManageBadges />} />
        <Route path={ROUTE_PATHS.ADMIN_APPS} element={<ExternalApp />} />
        <Route path={ROUTE_PATHS.ADMIN_CARD_MANAGEMENT} element={<CardManagement />} />
        <Route path={ROUTE_PATHS.ADMIN_DELEGATES} element={<Delegates />} />
        <Route path={ROUTE_PATHS.ADMIN_NOTIFICATIONS} element={<NotificationScheduler />} />
        <Route path={ROUTE_PATHS.ADMIN_PULSE} element={<PulseScheduler />} />
        <Route path={ROUTE_PATHS.DSYS} element={<DSYS />} />
        <Route path={ROUTE_PATHS.NEWS} loader={newsLoader} element={<News />} />
        <Route path={ROUTE_PATHS.NEWS_POST} loader={newsLoader} element={<News />} />
        <Route path={ROUTE_PATHS.NEWS_NEW} loader={newsLoader} element={<NewsCreateView />} />
        <Route path={ROUTE_PATHS.NEWS_EDIT} loader={newsLoader} element={<NewsUpdateView />} />
        <Route path={ROUTE_PATHS.DASHBOARDS} element={<Dashboards />} />
        <Route path={ROUTE_PATHS.DASHBOARDS_TEAM} element={<Dashboards />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_NEW} element={<WorkstreamCreate />} />
        <Route
          path={ROUTE_PATHS.WORKSTREAM_EDIT}
          loader={workstreamLoader}
          element={<WorkstreamEditor />}
        />
        <Route path={ROUTE_PATHS.WORKSTREAM_TRACKERS} element={<Trackers />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_TRACKER_EDIT} element={<TrackerEditor />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_TRACKER_NEW} element={<TrackerCreate />} />
        <Route path={ROUTE_PATHS.WORKSTREAM} element={<TrackerWorkstream />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_REPORTS} element={<TrackerWorkstream reports />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_NEW} element={<WorkstreamCreate />} />
        <Route path={ROUTE_PATHS.WORKSTREAM_EDIT} element={<WorkstreamEditor />} />
      </Route>
      <Route path={ROUTE_PATHS.WAITLIST} element={<Waitlist />}></Route>
      <Route path="*" element={<NotFound />} />
    </>
  )
);
