/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Workstream } from '../models/Workstream';
import type { WorkstreamCreate } from '../models/WorkstreamCreate';
import type { WorkstreamEdit } from '../models/WorkstreamEdit';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkstreamService {

    /**
     * Create
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static createApiV1WorkstreamPost(
        requestBody: WorkstreamCreate,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit
     * @param reason
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static editApiV1WorkstreamPut(
        reason: (string | null),
        requestBody: WorkstreamEdit,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workstream',
            query: {
                'reason': reason,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive
     * @param id
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static archiveApiV1WorkstreamIdArchivePost(
        id: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workstream/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getAllApiV1WorkstreamAllGet(): CancelablePromise<Array<Workstream>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/all',
        });
    }

    /**
     * Get Self
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getSelfApiV1WorkstreamSelfGet(): CancelablePromise<Array<Workstream>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/self',
        });
    }

    /**
     * Get Reports
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getReportsApiV1WorkstreamReportsGet(): CancelablePromise<Array<Workstream>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/reports',
        });
    }

    /**
     * Seed
     * @returns string Successful Response
     * @throws ApiError
     */
    public static seedApiV1WorkstreamSeedGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/seed',
        });
    }

    /**
     * Get
     * @param id
     * @returns Workstream Successful Response
     * @throws ApiError
     */
    public static getApiV1WorkstreamIdGet(
        id: number,
    ): CancelablePromise<Workstream> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workstream/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
