import {useEffect} from 'react';

// Define the hook's parameters and types RefObject<ControlRefObject>
export const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // ignore clicks on the dig-Menu-content since its overlay is not in ref
      const digMenuElement = document.getElementsByClassName('dig-Menu-content');
      // if the element node is not null and the click event target is the element node
      for (let i = 0; i < digMenuElement.length; i++) {
        if (digMenuElement[i] !== null && digMenuElement[i].contains(event.target as Node)) {
          return;
        }
      }
      // Check if the click is outside the ref element
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(); // Call the callback if a click outside is detected
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]); // Re-run the hook if the ref or callback changes
};
