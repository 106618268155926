import {useMutation, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {Team, TeamEdit, TeamService} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export interface TeamsTreeItemOld {
  teamName: string;
  teamId: string;
  slug: string;
  subTeams?: TeamsTreeItemOld[];
}

export const useTeamsTreeSuspenseOld = (): TeamsTreeItemOld[] => {
  const {data} = useSuspenseQuery({
    queryKey: ['teamTree', 'old'],
    queryFn: getService(TeamService).getTeamsApiV1TeamsGet,
  });

  return data;
};

export const useTeamsTreeSuspense = () => {
  const {data} = useSuspenseQuery({
    queryKey: ['teams', 'tree', 'all'],
    queryFn: getService(TeamService).getTreeAllApiV1TeamsTreeGet,
  });

  return data;
};

export const useTeamsTree = () =>
  useQuery({
    queryKey: ['teams', 'tree', 'all'],
    queryFn: getService(TeamService).getTreeAllApiV1TeamsTreeGet,
  });

export const useTeamsCountTree = () =>
  useQuery({
    queryKey: ['teams', 'tree', 'counts', 'all'],
    queryFn: getService(TeamService).getTreeCountsAllApiV1TeamsTreecountsGet,
  });

export const useTeamEdit = () => {
  const {mutateAsync: editTeam, isPending} = useMutation({
    mutationFn: ({teamId, data}: {teamId: string; data: TeamEdit; resetTree?: boolean}) =>
      getService(TeamService).editApiV1TeamSlugPut(teamId, data),
    onSettled: (response, _, {resetTree}) => {
      if (!response) {
        return;
      }

      if (resetTree) {
        queryClient.invalidateQueries({queryKey: ['teamTree', 'old']});
        queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'all']});
        queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'counts', 'all']});
      }

      queryClient.invalidateQueries({queryKey: ['team', response]});
      queryClient.invalidateQueries({queryKey: ['team', 'logs', response]});
      queryClient.invalidateQueries({
        queryKey: ['team', 'hierarchy', response],
      });
    },
  });

  return {editTeam, isPending};
};

export const useTeamDelete = () => {
  const {mutateAsync: deleteTeam, isPending} = useMutation({
    mutationFn: (slug: string) => getService(TeamService).archiveApiV1TeamSlugArchivePost(slug),
    onSettled: (response, _, slug) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['teamTree', 'old']});
      queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'all']});
      queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'counts', 'all']});
      queryClient.invalidateQueries({queryKey: ['team', slug]});
      queryClient.invalidateQueries({
        queryKey: ['team', 'hierarchy', slug],
      });
    },
  });

  return {deleteTeam, isPending};
};

export const useTeamCreate = () => {
  const {mutateAsync: createTeam, isPending} = useMutation({
    mutationFn: ({data}: {data: TeamEdit}) => getService(TeamService).createApiV1TeamPost(data),
  });

  return {createTeam, isPending};
};

interface TeamCount {
  employees: number;
  teams: number;
}

export const useTeamsCountSuspense = (): TeamCount => {
  const {
    data: {employees, teams},
  } = useSuspenseQuery({
    queryKey: ['teamCount'],
    queryFn: getService(TeamService).getCountApiV1CountGet,
  });

  return {employees, teams};
};

export const useTeamSuspense = ({
  slug,
  includeSubteamMembers = false,
}: {
  slug: string;
  includeSubteamMembers?: boolean;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['team', slug, includeSubteamMembers],
    queryFn: () =>
      slug
        ? getService(TeamService).getTeamBySlugApiV1TeamsSlugSlugGet(slug, includeSubteamMembers)
        : null,
  });

  return data;
};

export const useHierarchy = ({slug}: {slug?: string}) => {
  const {data} = useQuery({
    queryKey: ['team', 'hierarchy', slug],
    queryFn: () => getService(TeamService).getHierarchyApiV1TeamsSlugHierarchyGet(slug ?? ''),
    enabled: !!slug,
  });

  return data;
};

export const useHierarchySuspense = ({slug}: {slug: string}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['team', 'hierarchy', slug],
    queryFn: () => getService(TeamService).getHierarchyApiV1TeamsSlugHierarchyGet(slug),
  });

  return data;
};

export const useTeam = ({slug}: {slug?: string}) => {
  const {data} = useQuery({
    queryKey: ['team', slug],
    queryFn: () => getService(TeamService).getTeamBySlugApiV1TeamsSlugSlugGet(slug ?? ''),
    enabled: !!slug,
  });

  return data;
};

export const useTeamMembers = ({slug}: {slug?: string}) => {
  const {data} = useQuery({
    queryKey: ['team', 'members', slug],
    queryFn: () => getService(TeamService).getTeamMembersApiV1TeamsSlugSlugMembersGet(slug ?? ''),
    enabled: !!slug,
  });

  return data;
};

export const useTeamsMapSuspense = ({slugs}: {slugs: string[]}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['teams', slugs],
    queryFn: () =>
      slugs.length
        ? getService(TeamService).getTeamBySlugsApiV1TeamsSlugsSlugsGet(slugs.join(','))
        : [],
  });

  const teamsMap = data.reduce((acc, team) => {
    return {
      ...acc,
      [team.slug ?? '']: team,
    };
  }, {}) as Record<string, Team>;

  return teamsMap;
};
