import {Button} from '@dropbox/dig-components/dist/buttons';
import {Table as DIGTable} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {pulseUserAtom} from 'atoms/auth';
import {App} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Header} from 'components/shared/table/Header';
import {LabelGroupCell, LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {ColumnConfig, useColumnResize} from 'components/shared/table/useColumnResize';
import {isSuperUser} from 'helpers/utils';
import {useAtomValue} from 'jotai';

import styles from './ExternalApp.module.css';
import {useExternalAppService} from './hooks';

const DSYSTableRow = ({name, appKey, allowed_ips, permissions}: App & {appKey: string}) => (
  <DIGTable.Row isSelectable className={styles.row}>
    <LabelGroupCell
      isBold
      text={name}
      subText={
        <Text size="small" color="subtle" className={styles.key}>
          {appKey}
        </Text>
      }
    />
    <LabelGroupSubtleCell text={permissions?.join(',')} />
    <LabelGroupSubtleCell text={allowed_ips?.join(',')} />
  </DIGTable.Row>
);

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 470},
    {type: 'permissions', width: 140},
    {type: 'allowed ips', short: 'ips', width: 100},
  ];
};

export const ExternalApp = () => {
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());
  const {apps, createApp} = useExternalAppService();

  const handleCreateApp = async () => {
    await createApp({name: 'Dropbox Directory', permissions: ['teams.readonly']});
  };

  const user = useAtomValue(pulseUserAtom);
  if (!user || (user && !isSuperUser(user.email))) {
    return null;
  }

  return (
    <Layout.Container size="condensed">
      <Button variant="outline" onClick={handleCreateApp} style={{display: 'none'}}>
        Create Dropbox Directory App
      </Button>

      <DIGTable hasDividers={false} spacing="small" verticalAlign="center">
        <Header
          columnConfigs={columnConfigs}
          dragging={dragging}
          getMouseDownHandler={getMouseDownHandler}
        />
        <DIGTable.Body>
          {apps?.map(({key: appKey, ...app}) => (
            <DSYSTableRow key={appKey} appKey={appKey} {...app} />
          ))}
        </DIGTable.Body>
      </DIGTable>
    </Layout.Container>
  );
};
