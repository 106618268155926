import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, ArrowLeftLine, PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Tracker, TrackerCreate, TrackerEdit, TrackerWorkstreamEdit} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export const TrackerModify = ({
  tracker,
  onSubmit,
  isEditing,
  isPending,
}: {
  onSubmit: (data: {data: TrackerCreate | TrackerEdit}) => Promise<number>;
  tracker?: Tracker;
  isPending: boolean;
  isEditing?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const autofocus = location.state?.autofocus;
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useDocumentTitle(isEditing ? t('edit_tracker') : t('add_tracker'));

  useEffect(() => {
    analyticsLogger().logEvent('TRACKER_MODIFY_VIEW', {edit: Boolean(isEditing), autofocus});
  }, [isEditing, autofocus]);

  const [trackerUpdate, setTrackerUpdate] = useState<TrackerEdit>(
    tracker
      ? {
          id: tracker.id,
          name: tracker.name,
          workstreams: tracker.workstreams,
        }
      : ({workstreams: []} as unknown as TrackerEdit)
  );

  const hasError = useMemo(() => {
    const {name} = trackerUpdate;

    if (!name) {
      return true;
    }

    return false;
  }, [trackerUpdate]);

  const handleSubmit = async () => {
    try {
      await onSubmit({
        data: {
          id: trackerUpdate.id,
          name: trackerUpdate.name,
          workstreams: trackerUpdate.workstreams,
        },
      });

      setSnackbarMessage({text: t('saved')});

      navigate(ROUTE_PATHS.WORKSTREAM_TRACKERS, {
        state: {source: isEditing ? 'modify' : 'create'},
      });
    } catch (e: any) {
      setSnackbarMessage({text: e});
    }
  };

  if (!loggedInEmployee?.email) {
    return null;
  }

  return (
    <Layout.InlineDrawerContainer
      open={isDrawerOpen}
      size="condensed"
      drawerHeader={<Title size={18}>{t('members_title')}</Title>}
      drawerIcon={PersonMultipleLine}
      drawerBody={<></>}
      onClose={() => setDrawerOpen(false)}
    >
      <Stack align="start">
        <ProjectHeader isEditing={isEditing} />

        <FormRow>
          <FormLabel
            withInput={
              <>
                <TextInput
                  required
                  autoFocus={!autofocus}
                  isInvalid={hasError && !trackerUpdate.name}
                  size="large"
                  width="100%"
                  placeholder={t('start_writing_placeholder')}
                  value={trackerUpdate.name}
                  onChange={(e) => setTrackerUpdate((p) => ({...p, name: e.target.value}))}
                />

                {/* <Text monospace className={atoms({margin: '16', paddingY: '8'})}>
                    https://os.dropbox.com/{teamUpdate.name.toLowerCase().replace(' ', '-')}
                  </Text> */}
              </>
            }
          >
            {t('tracker_edit_name')}
          </FormLabel>
        </FormRow>

        <FormRow>
          <FormLabel> {t('workstreams')}</FormLabel>
          <ReordableWorkstreams
            workstreamsO={trackerUpdate.workstreams}
            setWorkstreamsO={(workstreams) => setTrackerUpdate((p) => ({...p, workstreams}))}
          />
          <IconButton
            variant="outline"
            shape="circular"
            size="small"
            // onClick={() => setTrackerUpdate((p) => ({...p, workstreams: [...p.workstreams, {}]}))}
          >
            <Box as={UIIcon} src={AddLine} color="Border Base" />
          </IconButton>
        </FormRow>

        <FormRow>
          <EditSaveButtons
            cta={'Save'}
            disableSave={hasError}
            isLoading={isPending}
            handleCancelClick={() =>
              /* eslint-disable-next-line*/
              /* @ts-ignore */
              navigate(-1, {
                state: {source: isEditing ? 'modify' : 'create'},
              })
            }
            handleSaveClick={handleSubmit}
          />
        </FormRow>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

type WorkstreamSortWrapper = {
  id: number;
  workstreamId: number;
  data: TrackerWorkstreamEdit;
};

const ReordableWorkstreams = ({
  workstreamsO,
}: {
  workstreamsO: TrackerWorkstreamEdit[];
  setWorkstreamsO: (workstreams: TrackerWorkstreamEdit[]) => void;
}) => {
  const [workstreams, setWorkstreams] = useState<WorkstreamSortWrapper[]>(
    workstreamsO
      .sort((a, b) => a.order - b.order)
      .map((workstream, index) => ({
        id: index,
        workstreamId: workstream.workstream_id,
        data: workstream,
      }))
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setWorkstreams((items) => {
        const oldIndex = items.findIndex((entry) => entry.id === active.id);
        const newIndex = items.findIndex((entry) => entry.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <SortableContext items={workstreams} strategy={verticalListSortingStrategy}>
        {workstreams.map((workstream) => {
          return (
            <Box style={{marginBottom: -24}} key={workstream.id} width="100%">
              {workstream.id}
            </Box>
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

const ProjectHeader = ({isEditing}: {isEditing?: boolean}) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        variant="transparent"
        style={{position: 'absolute', marginTop: 4, marginLeft: -40}}
        onClick={() =>
          /* eslint-disable-next-line*/
          /* @ts-ignore */
          navigate(-1, {
            state: {source: isEditing ? 'modify' : 'create'},
          })
        }
      >
        <UIIcon src={ArrowLeftLine} />
      </IconButton>
      <Split alignY="center" paddingBottom="16" gap="6">
        <Split.Item width="fill">
          <Title size={18}>{isEditing ? t('edit_tracker') : t('add_tracker')}</Title>
        </Split.Item>
      </Split>
    </>
  );
};
