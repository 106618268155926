import {darkModeAtom} from 'hooks/useSetThemeWatcher';
import {atom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';

import {themePreferenceAtom} from './settings';

export const isMobileAtom = atom(false);

// Theme atom that's derived from the theme preference.
export const derivedThemeAtom = atom((get) => {
  const preference = get(themePreferenceAtom);
  if (preference !== 'auto') {
    return preference;
  }

  const isDarkMode = get(darkModeAtom);
  return isDarkMode ? 'dark' : 'bright';
});

// Feature flag atom, cached in local storage. It updates updates after each new get assignments request
export const growthbookCacheAtom = atomWithStorage<{
  isExportEnabled: boolean;
  isOrgChartEnabled: boolean;
  isGoalAiEnabled: boolean;
  isSpritesAiEnabled: boolean;
  isPulseEnabled: boolean;
  isDropboxOSEnabled: boolean;
}>('features', {
  isExportEnabled: false,
  isOrgChartEnabled: false,
  isGoalAiEnabled: false,
  isSpritesAiEnabled: false,
  isPulseEnabled: false,
  isDropboxOSEnabled: false,
});
