import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ActivityLine, BriefcaseLine, CodeLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {FolderMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {Employee, EmployeeService, JIRAEpic, Project} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Link} from 'components/DSYS/Link';
import {StatusButton} from 'components/DSYS/StatusButton';
import {Title} from 'components/DSYS/Title';
import {GoalsList} from 'components/goals/GoalsList';
import {useGoals} from 'components/goals/hooks';
import {DEFAULT_TIMEFRAME} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getService} from 'utilities';
import {TimeframeSelector} from 'views/goals_v2/TimeframeSelector';
import {ProjectCard} from 'views/projects/ProjectCard';

const limitProjects = 3;

// type narrow project | any where project has projec.team prop
const isProject = (project: Project | JIRAEpic): project is Project => 'team' in project;

export const MyWork = ({
  employee,
  hasProjects,
  hasGoals,
}: {
  employee: Employee;
  hasProjects: boolean;
  hasGoals: boolean;
}) => {
  const location = useLocation();
  const [projectTimeframe, setProjectTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );
  const pulseUser = useAtomValue(pulseUserAtom);

  const [selectedTab, setSelectedTab] = useState(hasGoals ? 'goals' : 'projects');

  const {data, isLoading} = useQuery({
    enabled: hasProjects,
    queryKey: ['employee', 'projects', employee.user_id, projectTimeframe],
    queryFn: () =>
      getService(EmployeeService).getProjectsApiV1PeopleEmployeeIdProjectsGet(
        employee.user_id,
        projectTimeframe
      ),
  });

  const {data: epics, isLoading: isEpicsLoading} = useQuery({
    queryKey: ['employee', 'epics', employee.ldap, projectTimeframe],
    queryFn: () =>
      getService(EmployeeService).getJiraApiV1EmployeeJiraGet(employee.ldap, projectTimeframe),
    retry: (failureCount) => failureCount <= 3, // JIRA calls just fail sometimes
  });
  const isOldTimeframe = projectTimeframe !== DEFAULT_TIMEFRAME;

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  const allData: (Project | JIRAEpic)[] = [
    ...(data?.projects ?? []),
    ...(epics?.filter(
      ({key}) =>
        data?.projects.some((projects) => projects.epics?.some(({jira}) => jira === key)) ?? []
    ) ?? []),
  ];

  const hasMulti = hasGoals && (hasProjects || epics);

  const {isFetched, goals} = useGoals({
    ldaps: [employee.ldap],
    timeframe: projectTimeframe,
  });

  useEffect(() => {
    if (isFetched && !goals.length && (hasProjects || epics?.length)) {
      setSelectedTab('projects');
    }
  }, [isFetched, hasProjects, epics?.length, goals.length]);

  useEffect(() => {
    setSelectedTab(hasGoals ? 'goals' : 'projects');
  }, [employee.ldap, hasGoals]);

  return (
    <Box
      as={Stack}
      width="100%"
      paddingX="20"
      paddingY="16"
      borderWidth="1"
      borderColor="Border Subtle"
      borderRadius="Medium"
      borderStyle="Solid"
    >
      <Split paddingBottom="8">
        <Split.Item width="fill">
          <Title size={16} withAccessoryStart={<UIIcon src={BriefcaseLine} isOpticallyAligned />}>
            {t('my_work')}
          </Title>
        </Split.Item>
        <Split.Item>
          <TimeframeSelector
            selectedTimeframe={projectTimeframe}
            setSelectedTimeframe={(next) => {
              analyticsLogger().logEvent('PROFILE_PROJECT_TIMEFRAME', {timeframe: next});
              setProjectTimeframe(next);
            }}
          />
        </Split.Item>
      </Split>
      {hasMulti ? (
        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
          <Tabs.Group>
            <Tabs.Tab id="goals">{t('goals')}</Tabs.Tab>
            <Tabs.Tab id="projects">{t('projects')}</Tabs.Tab>
            {/* <Tabs.Tab id="workstreams">{t('workstreams')}</Tabs.Tab> */}
          </Tabs.Group>

          <Box marginTop="8">
            <Tabs.Panel tabId="goals">
              <GoalsList
                type={employee.email === pulseUser?.email ? 'self' : 'employee'}
                ldaps={[employee.ldap]}
                timeframe={projectTimeframe}
                onClick={profileActionLog}
              />
            </Tabs.Panel>
            <Tabs.Panel tabId="projects">
              <ProjectTab
                isLoading={isLoading || isEpicsLoading}
                allData={allData}
                isOldTimeframe={isOldTimeframe}
                onClick={profileActionLog}
              />
            </Tabs.Panel>
          </Box>
        </Tabs>
      ) : selectedTab === 'goals' ? (
        <GoalsList
          type={employee.email === pulseUser?.email ? 'self' : 'employee'}
          ldaps={[employee.ldap]}
          timeframe={projectTimeframe}
          onClick={profileActionLog}
        />
      ) : (
        <ProjectTab
          isLoading={isLoading || isEpicsLoading}
          allData={allData}
          isOldTimeframe={isOldTimeframe}
          onClick={profileActionLog}
        />
      )}
    </Box>
  );
};

const ProjectTab = ({
  isLoading,
  allData,
  isOldTimeframe,
  onClick,
}: {
  isLoading: boolean;
  allData: (Project | JIRAEpic)[];
  isOldTimeframe: boolean;
  onClick: (action: string) => void;
}) => {
  const [showAll, setShowAll] = useState(false);

  return isLoading ? (
    <ProjectSkeleton />
  ) : allData.length ? (
    <>
      {allData.slice(0, showAll ? undefined : limitProjects).map((project) =>
        !isProject(project) ? (
          <EpicRow key={project.key} epic={project} onClick={onClick} />
        ) : (
          <Box
            key={project.id}
            padding="8"
            {...withShade({duration: 'None'})}
            borderRadius="Medium"
          >
            <Link to={`/projects/${project.id}`} hasNoUnderline>
              <ProjectCard
                isProfile
                title={project.title}
                subtitle={project.subtitle}
                projectId={project.id}
                onStatusClick={() => onClick('project')}
              />
            </Link>
          </Box>
        )
      )}
      {allData.length > limitProjects && (
        <Box paddingTop="12">
          <Button variant="transparent" size="small" onClick={() => setShowAll(!showAll)}>
            {showAll
              ? t('show_less')
              : t('show_more_count', {count: allData.length - limitProjects})}
          </Button>
        </Box>
      )}
    </>
  ) : (
    <EmptyState
      hideBorder
      paddingY="24"
      marginBottom="0"
      title={isOldTimeframe ? 'No projects for this quarter' : 'No projects just yet'}
      body={
        isOldTimeframe
          ? 'Try switching the quarter to another one.'
          : 'Projects will show up here once added, so check again later!'
      }
      image={<FolderMini width={64} altText={''} />}
    />
  );
};

const ProjectSkeleton = () => (
  <Box display="flex" flexDirection="column">
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Box marginTop="16">
      <Skeleton.Text size="small" width={50} />
    </Box>
  </Box>
);

const EpicRow = ({epic, onClick}: {epic: any; onClick: (action: string) => void}) => (
  <Link
    to={`https://jira.dropboxer.net/browse/${epic.key}`}
    hasNoUnderline
    {...withShade({
      className: atoms({display: 'block', padding: '8', borderRadius: 'Medium'}),
    })}
    onClick={() => onClick('epic')}
  >
    <Split>
      <Split.Item width="fill">
        <LabelGroup
          align="top"
          withLeftAccessory={
            <Box
              as={UIIcon}
              src={epic.type === 'epic' ? ActivityLine : CodeLine}
              display="block"
              size="small"
              marginTop="4"
              borderRadius="XSmall"
              color={epic.type === 'epic' ? 'Background Base' : 'Text Subtle'}
              style={{
                filter: 'opacity(0.85)',
                backgroundColor: epic.type === 'epic' ? 'rgba(171, 144, 219, 0.85)' : '#9B640033',
              }}
            />
          }
          withText={<Text isBold>{epic.title}</Text>}
          withSubtext={epic.key}
        />
      </Split.Item>
      <Split.Item>
        <StatusButton status={epic.status} isLabel />
      </Split.Item>
    </Split>
  </Link>
);
