import {Text} from '@dropbox/dig-components/dist/typography';
import {FilePaperLarge} from '@dropbox/dig-content-icons';
import {Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {OpenLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {strategiesAtom, StrategyItem} from 'atoms/strategies';
import {Accordion, AccordionIconButton} from 'components/DSYS/Accordion';
import {Breadcrumb} from 'components/DSYS/Breadcrumb';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {StrategyDetails} from 'components/strategies/Detail';
import {StrategyOverview} from 'components/strategies/Overview';
import {TimeframeSelector} from 'components/strategies/TimeframeSelector';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

import styles from './Strategies.module.css';

export const Strategies = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  const strategyOptions = ['Q1 FY25', '2024'];
  const [timeframe, setTimeframe] = useState(strategyOptions[0]);

  return !pulseUser?.email ? null : timeframe !== '2024' ? (
    <StrategiesNew options={strategyOptions} timeframe={timeframe} setTimeframe={setTimeframe} />
  ) : (
    <Layout.Container>
      <Stack gap="16">
        <Breadcrumb
          path={[
            {to: '/strategies', children: t('strategy_plural')},
            {to: '/strategies', children: '2024'},
          ]}
        />
        <Box display="flex" alignItems="center" paddingTop="8">
          <Title size={24}>{t('strategy_plural')}</Title>
          <TimeframeSelector
            options={strategyOptions}
            timeframe={timeframe}
            setTimeframe={setTimeframe}
          />
        </Box>
        <StrategiesOld />
      </Stack>
    </Layout.Container>
  );
};

const StrategiesNew = ({
  options,
  timeframe,
  setTimeframe,
}: {
  options: string[];
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}) => {
  const strategies = useAtomValue(strategiesAtom)['2025'];
  const params = useParams();

  return !params?.id ? (
    <Layout.Container
      breadcrumb={[
        {to: '/strategies', children: t('strategy_plural')},
        {to: '/strategies', children: '2025'},
      ]}
    >
      <Stack gap="16">
        <Box display="flex" alignItems="center" paddingTop="8">
          <Title size={24}>{t('strategy_plural')}</Title>
          <TimeframeSelector options={options} timeframe={timeframe} setTimeframe={setTimeframe} />
        </Box>
        <StrategyOverview timeframe={timeframe} strategy={strategies} />
      </Stack>
    </Layout.Container>
  ) : (
    <StrategyDetails timeframe={timeframe} setTimeframe={setTimeframe} />
  );
};

const StrategiesOld = () => {
  useDocumentTitle(t('strategy_plural'));

  const strategies = useAtomValue(strategiesAtom)['2024'];

  const renderIconButton = (isActive: boolean, toggle: () => void) => (
    <AccordionIconButton
      isActive={isActive}
      toggle={(e) => {
        e.stopPropagation();
        toggle();
      }}
    />
  );

  const renderStrategy = (
    strategy: StrategyItem,
    expanded: boolean,
    _active: boolean,
    toggle: () => void,
    level = 0
  ) => (
    <>
      <Box
        as="div"
        borderRadius="Medium"
        paddingX="16"
        marginTop="8"
        cursor="pointer"
        onClick={() => window.open(strategy.link, '_blank')}
        {...withShade({
          state: expanded ? 'animated' : undefined,
          className: styles.strategyContainer,
        })}
      >
        <div className={styles.strategyColumn}>
          {strategy.subStrategies && renderIconButton(expanded, toggle)}
        </div>
        <div className={styles.strategyColumn}>
          <FilePaperLarge className={styles.strategyIcon} />
        </div>
        <div className={styles.strategyColumn}>
          <div className={styles.strategyTextColumn}>
            <Text size="large" variant="paragraph" tagName="p" isBold>
              {strategy.name}
            </Text>
            <Text size="small" variant="label" tagName="span">
              {strategy.owners.map((owner, index) => (
                <>
                  <Link
                    to={'/people/' + owner.ldap}
                    hasNoUnderline
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {owner.name}
                  </Link>
                  {index < strategy.owners.length - 1 ? ', ' : ''}
                </>
              ))}
            </Text>
          </div>
          <div className={styles.strategyLinkColumn}>
            <Text size="small" variant="label" tagName="span">
              {t('view_doc')} <UIIcon src={OpenLine} size="small"></UIIcon>
            </Text>
          </div>
        </div>
      </Box>
      {expanded && strategy.subStrategies && (
        <Accordion items={strategy.subStrategies} renderItem={renderStrategy} level={level + 1} />
      )}
    </>
  );

  return <Accordion items={strategies} renderItem={renderStrategy} tabSize={0} />;
};
