import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleLine} from '@dropbox/dig-icons/assets';
import {isCommentInputFocusedAtom} from 'atoms/comment';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Comment, GoalData, Thread} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {AutoResizingTextArea} from 'components/shared/AutoResizingTextArea';
import {ConvertLinks} from 'components/shared/ConvertLinks';
import {calculateTimeAgo, TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect, useRef, useState} from 'react';

import {DrawerCommentReplyInput} from './CommentInput';
import {CommentMenu} from './CommentMenu';
import {useCommentService, useThreadService} from './hooks';
import Range from './Range';

export const transformCommentsToMap = (comments: Thread['comments']) => {
  return comments?.reduce(
    (acc, comment) => {
      return {
        ...acc,
        [comment.id]: comment.content,
      };
    },
    {} as Record<string, string>
  );
};

export const CommentsComponent = ({thread}: {thread: Thread; range: Range}) => {
  const {comments} = thread;
  const commentIdToContentMappping = transformCommentsToMap(comments);
  const [commentIdToEditContentMappping, setCommentIdToEditContentMappping] = useState<
    Record<string, string> | undefined
  >(commentIdToContentMappping);

  const {deleteComment, updateComment} = useCommentService({
    type: thread.attached_to_type,
    id:
      (['goal'].includes(thread.attached_to_type)
        ? thread.goal_id
        : thread.key_result_id
      )?.toString() ?? 'FIXME',
  });
  const {deleteThread} = useThreadService();
  const commentsEndRef = useRef<HTMLDivElement | null>(null);
  const commentInputFocused = useAtomValue(isCommentInputFocusedAtom);

  const [selectedEditCommentId, setSelectedEditCommentId] = useState<string | null>(null);

  const handleSelection = (value: string) => {
    const [action, commentId] = value.split(':');
    if (action === 'edit') {
      setSelectedEditCommentId(commentId);
    } else if (action === 'delete') {
      if (comments?.length === 1) {
        deleteThread({threadId: thread.id}).then(() => {
          deleteComment({commentId: commentId});
        });
      } else {
        deleteComment({commentId: commentId});
      }
    }
  };

  const handleEditedCommentDelete = (commentId: string) => {
    if (comments?.length === 1) {
      deleteThread({threadId: thread.id}).then(() => {
        deleteComment({commentId: commentId});
      });
    } else {
      deleteComment({commentId: commentId});
    }
  };

  const handleCommentEditSave = (commentId: string) => {
    const content = commentIdToEditContentMappping?.[commentId];

    updateComment({
      commentId: commentId,
      data: {
        content: content?.trim() ?? '',
        updated_at: new Date().toISOString(),
        is_deleted: false,
      },
    }).then(() => {
      setSelectedEditCommentId(null);
    });
  };

  const handleCommentEditCancel = () => {
    setCommentIdToEditContentMappping(transformCommentsToMap(comments));
  };

  const onContentChange = (value: string) => {
    setCommentIdToEditContentMappping((prev) => {
      return {
        ...prev,
        [selectedEditCommentId as string]: value,
      };
    });
  };

  const isEditted = (comment: Comment) => {
    return comment.updated_at !== comment.created_at;
  };

  useEffect(() => {
    setCommentIdToEditContentMappping(transformCommentsToMap(comments));
    if (commentsEndRef.current && commentInputFocused) {
      commentsEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [comments, commentInputFocused]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        borderBottom="Solid"
        borderColor="Border Subtle"
        style={{maxHeight: '280px', overflowY: 'auto'}}
      >
        {comments?.map((comment) => {
          return (
            <>
              <Split
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '16px',
                  paddingRight: '24px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  alignItems: 'flex-start',
                  gap: '4px',
                }}
              >
                <CommentHeader comment={comment} handleSelection={handleSelection} />
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingLeft="32"
                  width="100%"
                  alignItems="flex-start"
                >
                  {selectedEditCommentId !== comment.id && (
                    <Box
                      style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}
                    >
                      <Text>
                        <ConvertLinks
                          text={comment.content}
                          isBold={false}
                          hasNoUnderline={false}
                        />
                      </Text>
                    </Box>
                  )}
                  {selectedEditCommentId === comment.id && (
                    <>
                      <AutoResizingTextArea
                        content={commentIdToEditContentMappping?.[comment.id] ?? ''}
                        onBlurHandler={setSelectedEditCommentId}
                        onContentChange={onContentChange}
                      />
                      <Box
                        display="flex"
                        paddingTop="8"
                        alignItems="flex-start"
                        style={{borderTop: '1px solid var(--dig-color__border__subtle)'}}
                        width="100%"
                      >
                        <div
                          style={{
                            display: 'flex',
                            paddingTop: '8px',
                            borderTop: '1px solid var(--dig-color__border__subtle)',
                            width: '100%',
                          }}
                        >
                          <Split gap="6" alignX="center" alignY="center">
                            <Split.Item>
                              {commentIdToEditContentMappping?.[comment.id] ? (
                                <Box
                                  as={Button}
                                  variant="borderless"
                                  onMouseDown={() => handleCommentEditSave(comment.id)}
                                  size="small"
                                  disabled={!commentIdToEditContentMappping?.[comment.id]}
                                >
                                  <Text isBold>{t('save')}</Text>
                                </Box>
                              ) : (
                                <Box
                                  as={Button}
                                  variant="borderless"
                                  onMouseDown={() => handleEditedCommentDelete(comment.id)}
                                  size="small"
                                >
                                  <Text isBold>{t('delete')}</Text>
                                </Box>
                              )}
                            </Split.Item>
                            <Split.Item>
                              <Box as={Text} color="Text Subtle">
                                •
                              </Box>
                            </Split.Item>
                            <Split.Item>
                              <Box
                                as={Button}
                                variant="borderless"
                                onMouseDown={() => handleCommentEditCancel()}
                                size="small"
                              >
                                <Text>{t('cancel')}</Text>
                              </Box>
                            </Split.Item>
                          </Split>
                        </div>
                      </Box>
                    </>
                  )}
                  {selectedEditCommentId !== comment.id && isEditted(comment) && (
                    <Box as={Text} color="Text Subtle" size="small" marginTop="8">
                      {t('edited')}
                    </Box>
                  )}
                </Box>
              </Split>
            </>
          );
        })}
        <div ref={commentsEndRef} />
      </Box>
    </>
  );
};

const CommentHeader = ({
  comment,
  handleSelection,
}: {
  comment: Comment;
  handleSelection: (value: string) => void;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const [flexDirection, setFlexDirection] = useState('row');

  const isCommentOwner = (currentComment: Comment) => {
    return currentComment.employee.ldap === employee.email;
  };

  useEffect(() => {
    // Define the character limit for switching to column layout
    const charLimit = 20;
    const timeAgoChar = calculateTimeAgo(comment.created_at).length;
    if (comment.employee.name.length + timeAgoChar > charLimit) {
      setFlexDirection('column');
    } else {
      setFlexDirection('row');
    }
  }, [comment.employee.name, comment.created_at]);

  return (
    <Split
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '8px',
        justifyContent: 'space-between',
        width: '275px',
      }}
    >
      <Split gap="4" alignY={flexDirection == 'row' ? 'center' : 'top'}>
        <Split.Item>
          <Avatar user={comment.employee} size="small" />
        </Split.Item>
        <Split.Item paddingTop={flexDirection == 'row' ? '0' : '2'}>
          <Cluster gap="4">
            <Cluster.Item>
              <Text isBold _withoutWrap>
                {comment.employee.name}
              </Text>
            </Cluster.Item>
            <Cluster.Item>
              <Text color="faint" size="small">
                <TimeAgo timestamp={comment.created_at} />
              </Text>
            </Cluster.Item>
          </Cluster>
        </Split.Item>
      </Split>
      <Split.Item paddingTop={flexDirection == 'row' ? '0' : '2'}>
        {isCommentOwner(comment) && (
          <CommentMenu commentId={comment.id} handleSelection={handleSelection} />
        )}
      </Split.Item>
    </Split>
  );
};

const DrawerCommentHeader = ({
  comment,
  handleSelection,
  canResolve,
}: {
  comment: Comment;
  handleSelection: (value: string) => void;
  canResolve?: boolean;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const isCommentOwner = (currentComment: Comment) => {
    return currentComment.employee.user_id === employee.user_id;
  };

  const {updateThread} = useThreadService();

  const handleResolve = () => {
    updateThread({
      threadId: comment.thread_id,
      data: {is_resolved: true, is_deleted: false, attached_to_type: 'goal'},
    });
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '8px',
        // justifyContent: 'space-between',
        width: '100%',
        paddingRight: '12px',
      }}
    >
      <Avatar user={comment.employee} size="small" />
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%">
        <Cluster gap="4" alignY="top">
          <Cluster.Item>
            <Box display="flex" flexDirection="column">
              <Text isBold _withoutWrap>
                {comment.employee.name}
              </Text>
              <Text color="faint" size="small">
                <TimeAgo timestamp={comment.created_at} />
              </Text>
            </Box>
          </Cluster.Item>
          <Cluster.Item>
            <Text color="faint" size="small">
              <Truncate lines={1} maxWidth={215}>
                {comment.employee.role}
              </Truncate>
            </Text>
          </Cluster.Item>
        </Cluster>
        <Box display="flex" style={{marginTop: '-2px'}}>
          {canResolve && (
            <IconButton variant="borderless" shape="circular" size="small" onClick={handleResolve}>
              <UIIcon src={CheckmarkCircleLine}></UIIcon>
            </IconButton>
          )}
          {isCommentOwner(comment) && (
            <CommentMenu commentId={comment.id} handleSelection={handleSelection} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const DrawerCommentThread = ({
  goal,
  thread,
  activtedThread,
  setActivatedThread,
}: {
  goal: GoalData;
  thread: Thread;
  activtedThread: Thread['id'] | null;
  setActivatedThread: (id: Thread['id']) => void;
}) => {
  const {comments} = thread;

  const commentIdToContentMappping = transformCommentsToMap(comments);
  const [commentIdToEditContentMappping, setCommentIdToEditContentMappping] = useState<
    Record<string, string> | undefined
  >(commentIdToContentMappping);

  const {deleteComment, updateComment} = useCommentService({
    type: 'goal',
    id: thread.goal_id?.toString() ?? 'FIXME',
  });
  const {deleteThread} = useThreadService();

  const [selectedEditCommentId, setSelectedEditCommentId] = useState<string | null>(null);
  const [isInputAutoFocused, setIsInputAutoFocused] = useState(false);

  const isCurrentThreadActivated = activtedThread === thread.id;

  const commentsToShow = isCurrentThreadActivated ? comments : comments?.slice(0, 1);
  const replies = comments ? comments.length - 1 : 0;

  useEffect(() => {
    setCommentIdToEditContentMappping(transformCommentsToMap(comments));
  }, [comments]);

  const handleSelection = (value: string) => {
    const [action, commentId] = value.split(':');
    if (action === 'edit') {
      setSelectedEditCommentId(commentId);
    } else if (action === 'delete') {
      if (comments?.length === 1) {
        deleteThread({threadId: thread.id}).then(() => {
          deleteComment({commentId: commentId});
        });
      } else {
        deleteComment({commentId: commentId});
      }
    }
  };

  const handleEditedCommentDelete = (commentId: string) => {
    if (comments?.length === 1) {
      deleteThread({threadId: thread.id}).then(() => {
        deleteComment({commentId: commentId});
      });
    } else {
      deleteComment({commentId: commentId});
    }
  };

  const handleCommentEditSave = (commentId: string) => {
    const content = commentIdToEditContentMappping?.[commentId];

    updateComment({
      commentId: commentId,
      data: {
        content: content?.trim() ?? '',
        updated_at: new Date().toISOString(),
        is_deleted: false,
      },
    }).then(() => {
      setSelectedEditCommentId(null);
    });
  };

  const handleCommentEditCancel = () => {
    setCommentIdToEditContentMappping(transformCommentsToMap(comments));
  };

  const onContentChange = (value: string) => {
    setCommentIdToEditContentMappping((prev) => {
      return {
        ...prev,
        [selectedEditCommentId as string]: value,
      };
    });
  };

  const isEditted = (comment: Comment) => {
    return comment.updated_at !== comment.created_at;
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="Medium"
        width="100%"
        paddingTop="12"
        paddingBottom={isCurrentThreadActivated ? '0' : '4'}
        boxShadow={isCurrentThreadActivated ? 'Raised' : undefined}
        marginBottom={isCurrentThreadActivated ? '16' : '0'}
        style={{
          border: isCurrentThreadActivated
            ? '1px solid var(--dig-color__border__subtle)'
            : '1px solid transparent',
        }}
        className={`comment-thread-${thread.id}`}
      >
        {commentsToShow?.map((comment, i) => {
          return (
            <>
              <Split
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '16px',
                  paddingRight: '4px',
                  paddingTop: '8px',
                  paddingBottom:
                    i == commentsToShow.length - 1 && isCurrentThreadActivated ? '16px' : '8px',
                  alignItems: 'flex-start',
                  gap: '4px',
                }}
              >
                <DrawerCommentHeader
                  comment={comment}
                  handleSelection={handleSelection}
                  canResolve={i === 0}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingLeft="36"
                  paddingRight="12"
                  width="100%"
                  alignItems="flex-start"
                >
                  {selectedEditCommentId !== comment.id && (
                    <Box
                      style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}
                    >
                      <Text>
                        <ConvertLinks
                          text={comment.content}
                          isBold={false}
                          hasNoUnderline={false}
                        />
                      </Text>
                    </Box>
                  )}
                  {selectedEditCommentId === comment.id && (
                    <>
                      <AutoResizingTextArea
                        content={commentIdToEditContentMappping?.[comment.id] ?? 'test'}
                        onBlurHandler={setSelectedEditCommentId}
                        onContentChange={onContentChange}
                      />
                      <Box
                        display="flex"
                        paddingTop="8"
                        alignItems="flex-start"
                        style={{borderTop: '1px solid var(--dig-color__border__subtle)'}}
                        width="100%"
                      >
                        <div
                          style={{
                            display: 'flex',
                            paddingTop: '8px',
                            borderTop: '1px solid var(--dig-color__border__subtle)',
                            width: '100%',
                          }}
                        >
                          <Split alignX="center" alignY="center">
                            <Split.Item>
                              {commentIdToEditContentMappping?.[comment.id] ? (
                                <Box
                                  as={Button}
                                  variant="borderless"
                                  onMouseDown={() => handleCommentEditSave(comment.id)}
                                  size="small"
                                  disabled={!commentIdToEditContentMappping?.[comment.id]}
                                >
                                  <Text isBold>{t('save')}</Text>
                                </Box>
                              ) : (
                                <Box
                                  as={Button}
                                  variant="borderless"
                                  onMouseDown={() => handleEditedCommentDelete(comment.id)}
                                  size="small"
                                >
                                  <Text isBold>{t('delete')}</Text>
                                </Box>
                              )}
                            </Split.Item>
                            {/* <Split.Item>
                              <Box as={Text} color="Text Subtle">
                                •
                              </Box>
                            </Split.Item> */}
                            <Split.Item>
                              <Box
                                as={Button}
                                variant="borderless"
                                onMouseDown={() => handleCommentEditCancel()}
                                size="small"
                              >
                                <Text>{t('cancel')}</Text>
                              </Box>
                            </Split.Item>
                          </Split>
                        </div>
                      </Box>
                    </>
                  )}
                  {selectedEditCommentId !== comment.id && isEditted(comment) && (
                    <Box as={Text} color="Text Subtle" size="small" marginTop="8">
                      {t('edited')}
                    </Box>
                  )}
                </Box>
              </Split>
            </>
          );
        })}
        {!isCurrentThreadActivated && (
          <Box
            display="flex"
            alignItems="center"
            style={{gap: '10px', paddingLeft: '52px', marginTop: '4px'}}
          >
            <Button
              variant="transparent"
              size="small"
              hasNoUnderline
              onClick={() => {
                setActivatedThread(thread.id);
                setIsInputAutoFocused(true);
              }}
            >
              <Box as={Text} color="Text Subtle" size="small" variant="label" isBold>
                {t('reply')}
              </Box>
            </Button>
            {replies > 0 && (
              <>
                <Box
                  as="div"
                  style={{width: '40px', border: '1px solid var(--dig-color__border__base)'}}
                ></Box>
                <Button
                  variant="transparent"
                  size="small"
                  hasNoUnderline
                  onClick={() => setActivatedThread(thread.id)}
                >
                  <Box as={Text} color="Text Subtle" size="small" variant="label" isBold>
                    {t('reply', {count: replies})}
                  </Box>
                </Button>
              </>
            )}
          </Box>
        )}
        {isCurrentThreadActivated && (
          <Box
            style={{
              borderTop: isInputAutoFocused
                ? '1px solid transparent'
                : '1px solid var(--dig-color__border__subtle)',
            }}
          >
            <DrawerCommentReplyInput
              thread={thread}
              goal={goal}
              autoFocus={isInputAutoFocused}
              setAutoFocus={setIsInputAutoFocused}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
