import {BoxProps} from '@dropbox/dig-foundations';
import {useCallback, useState} from 'react';

export interface ColumnConfig {
  type: string;
  width: number;
  short?: string;
  minWidth?: number;
  maxWidth?: number;
  fixed?: boolean;
  toggletip?: string;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  paddingRight?: BoxProps<'div'>['paddingRight'];
  paddingTop?: BoxProps<'div'>['paddingTop'];
}

const MINIMUM_COLUMN_WIDTH = 30;
const MAXIMUM_COLUMN_WIDTH = 1500;

export function useColumnResize(initialConfig: ColumnConfig[] = []) {
  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>(initialConfig);
  const [dragging, setDragging] = useState<string>();

  const getMouseDownHandler = useCallback(
    (columnIndex: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const startX = e.clientX;
      const startWidth = columnConfigs[columnIndex].width;

      const doDrag = (mouseMoveEvent: MouseEvent) => {
        setDragging(columnConfigs[columnIndex].type);

        columnConfigs[columnIndex].width = Math.min(
          columnConfigs[columnIndex].maxWidth ?? MAXIMUM_COLUMN_WIDTH,
          Math.max(
            startWidth + mouseMoveEvent.clientX - startX,
            columnConfigs[columnIndex].minWidth ?? MINIMUM_COLUMN_WIDTH
          )
        );

        setColumnConfigs([...columnConfigs]);
      };

      const stopDrag = () => {
        setDragging(undefined);
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    },
    [columnConfigs]
  );

  return {columnConfigs, dragging, getMouseDownHandler, overrideColumnConfigs: setColumnConfigs};
}
