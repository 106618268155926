import emojiRegex from 'emoji-regex';
import React, {FunctionComponent, ReactNode, useState} from 'react';

import {debounce, getUrl, isSafari} from './helpers';
import EmojiNode from './nodes/EmojiNode';
import Node from './nodes/Node';
import UrlNode from './nodes/UrlNode';
import Range from './Range';

export type CustomHTMLNode = {
  dataset: {
    position: string;
  };
} & ParentNode;

export type HighlightStyle = Record<string, string>;
export type HighlightStyleFunc = (range: Range, charIndex: number) => HighlightStyle;

export type OnMouseOverHighlightedWord = (range: Range) => void;
export type OnMouseOverHighlightedWordHandler = (range: Range, isVisible: boolean) => void;

export interface HighlightableProps {
  ranges?: Range[];
  onMouseOverHighlightedWord: OnMouseOverHighlightedWord;
  id: string;
  highlightStyle?: HighlightStyle | HighlightStyleFunc;
  text: string;
  enabled: boolean;
  rangeRenderer?: (
    currentRenderedNodes: React.JSX.Element[],
    currentRenderedRange: Range,
    currentRenderedIndex: number,
    onMouseOverHighlightedWord: OnMouseOverHighlightedWordHandler
  ) => React.JSX.Element;
  nodeRenderer?: (
    charIndex: number,
    range: Range | undefined,
    text: string,
    url: string,
    isEmoji: boolean
  ) => React.JSX.Element;
  style: Record<string, string>;
  onTextHighlighted: (range: Range) => void;
}

export const Highlightable: FunctionComponent<HighlightableProps> = ({
  ranges,
  onMouseOverHighlightedWord,
  id,
  highlightStyle,
  text,
  enabled,
  rangeRenderer,
  nodeRenderer,
  style,
  onTextHighlighted,
}: HighlightableProps) => {
  // const dismissMouseUp = 0;

  // let doucleckicked = false;
  const [doucleckicked, setDoucleckicked] = useState(false);
  const [mouseUp, setMouseUp] = useState(0);

  const getRange = (charIndex: number) => {
    return ranges && ranges.find((r) => charIndex >= r.start && charIndex <= r.end);
  };

  const onMouseOverHighlightedWordHandler = (range: Range, visible: boolean): void => {
    if (visible && onMouseOverHighlightedWord) {
      onMouseOverHighlightedWord(range);
    }
  };

  const getLetterNode = (charIndex: number, range?: Range) => {
    return (
      <Node
        id={id}
        range={range}
        charIndex={charIndex}
        key={`${id}-${charIndex}`}
        highlightStyle={highlightStyle}
      >
        {text[charIndex]}
      </Node>
    );
  };

  const getEmojiNode = (charIndex: number, range?: Range) => {
    return (
      <EmojiNode
        text={text}
        id={id}
        range={range}
        key={`${id}-emoji-${charIndex}`}
        charIndex={charIndex}
        highlightStyle={highlightStyle}
      />
    );
  };

  const getUrlNode = (charIndex: number, url: string, range?: Range) => {
    return (
      <UrlNode
        url={url}
        id={id}
        range={range}
        key={`${id}-url-${charIndex}`}
        charIndex={charIndex}
        highlightStyle={highlightStyle}
      />
    );
  };

  const mouseEvent = () => {
    if (!enabled) {
      return false;
    }

    let t = '';

    if (window.getSelection) {
      const selection = window.getSelection();
      t = selection ? selection.toString() : '';
    } else if (document.getSelection() && document.getSelection()?.type !== 'Control') {
      t = document.createRange().toString();
    }

    if (!t || !t.length) {
      return false;
    }

    const r = window.getSelection()?.getRangeAt(0);

    const startContainerPosition = parseInt(
      (r?.startContainer.parentNode as CustomHTMLNode).dataset.position
    );
    const endContainerPosition = parseInt(
      (r?.endContainer.parentNode as CustomHTMLNode).dataset.position
    );

    let startHL =
      startContainerPosition < endContainerPosition ? startContainerPosition : endContainerPosition;
    const endHL =
      startContainerPosition < endContainerPosition ? endContainerPosition : startContainerPosition;

    if (isSafari() && startHL > 0 && mouseUp == 0) {
      startHL++;
    }

    const rangeObj = new Range(startHL, endHL, text, {
      // changed by me!
      ranges: undefined,
      onMouseOverHighlightedWord,
      id,
      text,
      enabled,
      rangeRenderer,
      onTextHighlighted,
      style,
    });

    onTextHighlighted(rangeObj);
  };

  const onMouseUp = () => {
    setMouseUp((prev) => prev + 1);
    mouseEvent();
    debounce(() => {
      setMouseUp((prev) => prev - 1);
      if (doucleckicked) {
        // doucleckicked = false;
        setDoucleckicked(false);
      }
    }, 300)();
  };

  const onDoubleClick = () => {
    // e.stopPropagation();
    setDoucleckicked(() => true);
    mouseEvent();
  };

  const rangeRendererDefault = (
    letterGroup: React.JSX.Element[],
    range: Range,
    textCharIndex: number,
    onMouseOverHighlightedWordFunc: OnMouseOverHighlightedWordHandler
  ) => {
    return rangeRenderer
      ? rangeRenderer(letterGroup, range, textCharIndex, onMouseOverHighlightedWordFunc)
      : letterGroup;
  };

  // charIndex: number, range: Range, text: string, url: string, isEmoji: boolean
  const getNode = (
    i: number,

    t: string,
    url: string,
    isEmoji: boolean,
    r?: Range
  ): React.JSX.Element => {
    if (nodeRenderer) {
      return nodeRenderer(i, r, t, url, isEmoji);
    }

    if (url.length) {
      return getUrlNode(i, url, r);
    } else if (isEmoji) {
      return getEmojiNode(i, r);
    }

    return getLetterNode(i, r);
  };

  const getRanges = (): ReactNode => {
    const newText = [];

    let lastRange;

    // For all the characters on the text
    for (let textCharIndex = 0; textCharIndex < text.length; textCharIndex++) {
      const range = getRange(textCharIndex);
      const url = getUrl(textCharIndex, text);
      const isEmoji = emojiRegex().test(text[textCharIndex] + text[textCharIndex + 1]);

      // Get the current character node
      const node = getNode(textCharIndex, text, url, isEmoji, range);

      // If the next node is an url one, we fast forward to the end of it
      if (url.length) {
        textCharIndex += url.length - 1;
      } else if (isEmoji) {
        // Because an emoji is composed of 2 chars
        textCharIndex++;
      }

      if (!range) {
        newText.push(node);
        continue;
      }

      // If the char is in range
      lastRange = range;
      // We put the first range node on the array
      const letterGroup = [node];

      // For all the characters in the highlighted range
      let rangeCharIndex = textCharIndex + 1;

      for (; rangeCharIndex < range.end + 1; rangeCharIndex++) {
        const isEmojiIncluded = emojiRegex().test(
          `${text[rangeCharIndex]}${text[rangeCharIndex + 1]}`
        );

        if (isEmojiIncluded) {
          letterGroup.push(getEmojiNode(rangeCharIndex, range));
          // Because an emoji is composed of 2 chars
          rangeCharIndex++;
        } else {
          letterGroup.push(getNode(rangeCharIndex, text, url, isEmojiIncluded, range));
        }

        textCharIndex = rangeCharIndex;
      }

      newText.push(
        rangeRendererDefault(letterGroup, range, textCharIndex, onMouseOverHighlightedWordHandler)
      );
    }

    if (lastRange) {
      // Callback function
      onMouseOverHighlightedWordHandler(lastRange, true);
    }

    return newText;
  };

  const onMouseDown = () => {
    window.addEventListener('mouseup', onMouseUp, {once: true});
  };

  return (
    <div
      style={style}
      // onMouseUp={onMouseUp}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {getRanges()}
    </div>
  );
};
