import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';

const optionToTimeframe = (option: string) => {
  if (option === '2024') {
    return '2024';
  }
  return `${option.split(' ')[0]} ${option.split(' ')[1].replace('FY', '20')}`;
};

export const TimeframeSelector = ({
  options,
  timeframe,
  setTimeframe,
}: {
  options: string[];
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}) => (
  <Menu.Wrapper onSelection={setTimeframe} style={{marginRight: -8, marginLeft: 'auto'}}>
    {({getContentProps, getTriggerProps}) => (
      <>
        <Button variant="borderless" withDropdownIcon {...getTriggerProps()}>
          {optionToTimeframe(timeframe)}
        </Button>
        <Menu.Content {...getContentProps()} placement="bottom-end" style={{minWidth: 200}}>
          {options.map((option) => (
            <Menu.Segment key={option}>
              <Menu.ActionItem
                value={option}
                withTitle={option.split(' ')[0]}
                withRightAccessory={
                  option.includes('FY') && (
                    <Text size="small" color="faint">
                      {option.split(' ')[1].replace('FY', '20')}
                    </Text>
                  )
                }
              />
            </Menu.Segment>
          ))}
        </Menu.Content>
      </>
    )}
  </Menu.Wrapper>
);
