import {Box, ThemeMode, useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  CheckmarkLine,
  CircleStandardFilledFill,
  CircleStandardLine,
  FailFill,
  ZoomOutFill,
} from '@dropbox/dig-icons/assets';

type StatusButtonLabel = 'open' | 'closed' | 'draft';

interface StatusOption {
  value: StatusButtonType;
  color: string;
  icon: React.FC;
}

interface StatusButtonOption {
  label: StatusButtonLabel;
  statusOptions: StatusOption[];
}

export const statusButtonTypes = [
  'no_status',
  'on_track',
  'at_risk',
  'off_track',
  'complete',
  'cancelled',
  'missed',
  'draft',
] as const;

type StatusButtonType = (typeof statusButtonTypes)[number];

export const getStatusOptions = (mode: ThemeMode): StatusButtonOption[] => {
  const isDark = mode === 'dark';
  return [
    {
      label: 'open',
      statusOptions: [
        {value: 'on_track', color: isDark ? '#778F18' : '#CFF252', icon: CircleStandardFilledFill},
        {value: 'at_risk', color: isDark ? '#BF9B25' : '#FBDC7E', icon: CircleStandardFilledFill},
        {value: 'off_track', color: isDark ? '#AC5033' : '#FB9D83', icon: CircleStandardFilledFill},
      ],
    },
    {
      label: 'closed',
      statusOptions: [
        {value: 'complete', color: isDark ? '#627615' : '#B4DF1C', icon: CheckmarkCircleFill},
        {value: 'cancelled', color: isDark ? '#969390' : '#D3CECA', icon: ZoomOutFill},
        {value: 'missed', color: isDark ? '#9E3818' : '#F77751', icon: FailFill},
      ],
    },
    {
      label: 'draft',
      statusOptions: [{value: 'draft', color: '#BBB5AE', icon: CircleStandardLine}],
    },
  ];
};

export const getStatusStyle = (mode: ThemeMode, status?: string): StatusOption => {
  const noStatus: StatusOption = {
    value: 'no_status',
    color: '#BBB5AE',
    icon: CircleStandardLine,
  };

  const statusOptions = getStatusOptions(mode);
  return status
    ? statusOptions
        .find((option) => option.statusOptions.some((item) => item.value === status))
        ?.statusOptions.find((item) => item.value === status) || noStatus
    : noStatus;
};

export const getPublishedStatuses = (): StatusButtonType[] => {
  return statusButtonTypes.filter((option) => option !== 'draft');
};

export const StatusButtonIcon = ({
  size = 'standard',
  status,
  substatus,
  completeNew,
}: {
  size?: 'small' | 'standard';
  status?: string;
  substatus?: string;
  completeNew?: boolean;
}) => {
  const {mode} = useTheme();
  const statusStyle = getStatusStyle(mode, status);
  const subStatusStyle = substatus ? getStatusStyle(mode, substatus) : undefined;

  if (completeNew && statusStyle.value === 'complete') {
    statusStyle.icon = CheckmarkLine;
    statusStyle.color = 'var(--dig-color__text__subtle)';
  }

  return (
    <Box display="flex" alignItems="center">
      <UIIcon size={size} src={statusStyle.icon} color={statusStyle.color} />

      {subStatusStyle && statusStyle.value !== subStatusStyle?.value && (
        <>
          <Box
            as={UIIcon}
            src={CircleStandardFilledFill}
            color="Background Base"
            size="small"
            style={{width: 14, marginLeft: -9, marginTop: 10}}
          />
          <UIIcon
            src={subStatusStyle.icon}
            color={subStatusStyle.color}
            size="small"
            style={{width: 10, marginLeft: -12, marginTop: 10}}
          />
        </>
      )}
    </Box>
  );
};
