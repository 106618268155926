import {Chip} from '@dropbox/dig-components/dist/chip';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Typeahead, WrapperRef} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  BriefcaseLine,
  CompassLine,
  NewsLine,
  PersonMultipleLine,
  SearchLine,
  SettingsLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {showSearchAtom} from 'atoms/search';
import {Avatar} from 'components/DSYS/Avatar';
import {calculateTimeAgo} from 'components/shared/TimeAgo';
import {useDebouncedValue} from 'hooks/useDebounce';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {
  GlobalSearchResults,
  INITIAL_RESULTS,
  isCustom,
  isEmployee,
  isGoal,
  isNewsPost,
  isProject,
  isStrategy,
  isTeam,
  MAX_RESULTS,
  useGlobalSearch,
} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtom, useAtomValue} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

import styles from './PeopleSearch.module.css';
import styles2 from './Search.module.css';

const excludedFiltersForExternalRelease = ['goals', 'projects', 'strategies'];
const filters = ['all', 'people', 'teams', 'goals', 'projects', 'strategies', 'news'] as const;

export type Filters = (typeof filters)[number];

export const RouteToIcon = {
  people: PersonMultipleLine,
  teams: TeamLine,
  goals: TargetLine,
  projects: BriefcaseLine,
  strategies: CompassLine,
  news: NewsLine,
};

const groupedResults = (results: GlobalSearchResults[] = []) => {
  const people = results.filter((item) => isEmployee(item)).map((item, idx) => ({idx, ...item}));
  const teams = results.filter((item) => isTeam(item)).map((item, idx) => ({idx, ...item}));
  const projects = results.filter((item) => isProject(item)).map((item, idx) => ({idx, ...item}));
  const goals = results.filter((item) => isGoal(item)).map((item, idx) => ({idx, ...item}));
  const strategies = results
    .filter((item) => isStrategy(item))
    .map((item, idx) => ({idx, ...item}));
  const news = results.filter((item) => isNewsPost(item)).map((item, idx) => ({idx, ...item}));

  return {
    all: results.map((item, idx) => ({idx, ...item})),
    people,
    teams,
    goals,
    projects,
    strategies,
    news,
  };
};

const renderSubtitle = (result?: string | null) => {
  return (
    <Text size="small" color="faint">
      <Truncate lines={1}>{result}</Truncate>
    </Text>
  );
};

const renderTitle = (result?: string | null, hasSubtitle: boolean = true) => {
  return (
    <Box marginY={hasSubtitle ? '0' : '8'}>
      <Text isBold>
        <Truncate lines={1}>{result}</Truncate>
      </Text>
    </Box>
  );
};

const renderTypeaheadRow = (item: GlobalSearchResults & {idx: number}) => {
  if (isGoal(item)) {
    return (
      <Typeahead.Row
        key={item.id}
        value={{
          route: 'goals',
          path: item.id,
          index: item.idx,
          level: isEmployee(item) ? item.level : undefined,
          label: item.title,
        }}
        withTitle={renderTitle(item.title)}
        withSubtitle={renderSubtitle(
          `${item.users?.[0]?.display_name ?? ''} • ${item.timeframe} • ${t(
            'project_last_updated',
            {
              date: calculateTimeAgo(item.updated_at),
            }
          )}`
        )}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={RouteToIcon['goals']}
            size="large"
          />
        }
      />
    );
  } else if (isEmployee(item)) {
    return (
      <Typeahead.Row
        key={item.user_id}
        value={{
          route: 'people',
          path: item.ldap,
          index: item.idx,
          level: item.level,
          label: item.name,
        }}
        withTitle={renderTitle(item.name)}
        withSubtitle={renderSubtitle(item.is_deactivated ? 'Deactivated' : item.role)}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <Avatar user={item} size="large" isInactive={Boolean(item.is_deactivated)} />
        }
      />
    );
  } else if (isTeam(item)) {
    return (
      <Typeahead.Row
        key={item.team_id}
        value={{route: 'teams', path: item.slug, index: item.idx, label: item.name}}
        withTitle={renderTitle(item.name, Boolean(item.description?.length))}
        withSubtitle={renderSubtitle(item.description)}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={RouteToIcon['teams']}
            size="large"
          />
        }
      />
    );
  } else if (isStrategy(item)) {
    return (
      <Typeahead.Row
        key={item.id}
        value={{route: 'strategies', path: item.link, index: item.idx, label: item.name}}
        withTitle={renderTitle(item.name, Boolean(item.name))}
        withSubtitle={renderSubtitle(
          `${item.owners.map((owner, index) => owner.name + (index < item.owners.length - 1 ? ', ' : '')).join('')} • ${item.year}`
        )}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={RouteToIcon['strategies']}
            size="large"
          />
        }
      />
    );
  } else if (isCustom(item)) {
    return (
      <Typeahead.Row
        key={item.route}
        value={{route: item.route, index: item.idx, label: t(item.route)}}
        withTitle={renderTitle(t(item.route))}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={SettingsLine}
            size="large"
          />
        }
      />
    );
  } else if (isProject(item)) {
    return (
      <Typeahead.Row
        key={item.id}
        value={{route: 'projects', path: item.id, index: item.idx, label: item.title}}
        withTitle={renderTitle(item.title)}
        withSubtitle={renderSubtitle(item.subtitle)}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={RouteToIcon['projects']}
            size="large"
          />
        }
      />
    );
  } else if (isNewsPost(item)) {
    const date = new Date(item.created_at);
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);

    const categoryString = item.category[0] ? item.category[0].name : '';
    const categoryLabel = categoryString ? categoryString + ' • ' : '';

    return (
      <Typeahead.Row
        key={item.id}
        value={{route: 'news', path: item.id, index: item.idx, label: item.title}}
        withTitle={renderTitle(item.title)}
        withSubtitle={renderSubtitle(categoryLabel + formattedDate)}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <UIIcon
            style={{marginLeft: '5px', marginRight: '5px'}}
            color="var(--dig-color__text__subtle)"
            src={RouteToIcon['news']}
            size="large"
          />
        }
      />
    );
  } else if (!item) {
    return (
      <Typeahead.Row
        key="undefined"
        disabled
        withTitle={<Skeleton.Text width={100} />}
        withSubtitle={<Skeleton.Text width={330} />}
        className={styles.typeaheadRow}
        withLeftAccessory={
          <Skeleton.Avatar size="large" style={{marginLeft: '5px', marginRight: '5px'}} />
        }
      />
    );
  }
};

export const recentSearchAtom = atomWithStorage(
  'search',
  [] as {label: string; route: string; path: string}[]
);

export const GlobalSearch = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef<WrapperRef>();
  const [showSearch, setShowSearch] = useAtom(showSearchAtom);
  const [selectedFilter, setSelectedFilter] = useState<Filters>('all');
  const [hasQueryParam, setHasQueryParam] = useState(false);
  const [recentSearches, setRecentSearches] = useAtom(recentSearchAtom);

  // default input to the query param
  const [searchParams] = useSearchParams();
  const [userInputValue, setUserInputValue] = useState(
    searchParams.get('s')
      ? decodeURIComponent(
          (searchParams.get('s') || '').replace(/%(?![A-Fa-f0-9]{2})/g, '%25')
        ).trim()
      : ''
  );
  const debouncedInput = useDebouncedValue(userInputValue, 200);
  const {data: searchResult, isLoading} = useGlobalSearch({
    input: debouncedInput.trim(),
    filter: selectedFilter,
    showMore: true,
  });
  const grouped = useMemo(() => groupedResults(searchResult), [searchResult]);
  const filteredResults = grouped[selectedFilter];

  const handleSelection = useCallback(
    ({
      route,
      path,
      label,
      level,
      index = 0,
    }: {
      route: string;
      path: string;
      label: string;
      level?: string;
      index?: number;
    }) => {
      const recentSearchesCopy = recentSearches.filter((recent) => recent.path !== path);
      recentSearchesCopy.unshift({label, route, path});
      if (recentSearchesCopy.length >= 8) {
        recentSearchesCopy.length = 8;
      }
      setRecentSearches(recentSearchesCopy);

      analyticsLogger().logEvent('SEARCH_SELECTED', {
        route,
        filter: selectedFilter,
        results: filteredResults.length,
        input: debouncedInput.length,
        level,
        index,
      });
      setShowSearch(false);
      if (route === 'strategies') {
        window.open(path, '_blank');
        return;
      }
      navigate(`/${route}/${path ?? ''}`, {
        state: {source: showSearch === 'queryparam' ? 'queryparam' : 'search'},
      });
    },
    [
      debouncedInput.length,
      filteredResults.length,
      navigate,
      recentSearches,
      selectedFilter,
      setRecentSearches,
      setShowSearch,
      showSearch,
    ]
  );

  const loadFirstResult = useCallback(() => {
    if (isEmployee(filteredResults[0])) {
      handleSelection({
        route: 'people',
        path: filteredResults[0].ldap,
        level: filteredResults[0].level ?? undefined,
        label: filteredResults[0].name,
      });
    } else if (isTeam(filteredResults[0])) {
      handleSelection({
        route: 'teams',
        path: filteredResults[0].slug ?? '',
        label: filteredResults[0].name ?? '',
      });
    } else if (isProject(filteredResults[0])) {
      handleSelection({
        route: 'projects',
        path: filteredResults[0].id.toString(),
        label: filteredResults[0].title,
      });
    } else if (isGoal(filteredResults[0])) {
      handleSelection({
        route: 'goals',
        path: filteredResults[0].id.toString(),
        label: filteredResults[0].title,
      });
    } else if (isStrategy(filteredResults[0])) {
      handleSelection({route: 'strategies', path: '', label: filteredResults[0].name});
    } else if (isNewsPost(filteredResults[0])) {
      handleSelection({
        route: 'news',
        path: filteredResults[0].id.toString(),
        label: filteredResults[0].title,
      });
    }
  }, [filteredResults, handleSelection]);

  useEffect(() => {
    if (searchParams.get('s')) {
      setHasQueryParam(true);
    }
    analyticsLogger().logEvent('SEARCH_SHOWN', {source: showSearch});
    // Ignore changes to the search param. We only want to update the input value the first time it loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasQueryParam && filteredResults.length === 1) {
      loadFirstResult();
    }
  }, [hasQueryParam, filteredResults, loadFirstResult]);

  useEffect(() => {
    if (debouncedInput.length === 0) {
      setSelectedFilter('all');
    }
  }, [debouncedInput.length]);

  useEffect(() => {
    analyticsLogger().logEvent('SEARCH_FILTER_RESULTS', {
      filter: selectedFilter,
      results: filteredResults.length,
      input: debouncedInput.length,
    });
  }, [selectedFilter, filteredResults.length, debouncedInput.length]);

  const setFilterAndLog = (filter: Filters) => {
    analyticsLogger().logEvent('SEARCH_FILTER_SELECTED', {
      filter,
      results: filteredResults.length,
      input: debouncedInput.length,
    });
    setSelectedFilter(filter);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(e.currentTarget.value);
  };

  const handleTab = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      wrapperRef.current?.focusTrigger();

      const currentIndex = filters.indexOf(selectedFilter);
      const nextIndex = e.shiftKey
        ? (currentIndex + filters.length - 1) % filters.length
        : (currentIndex + 1) % filters.length;
      setFilterAndLog(filters[nextIndex] as Filters);
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && filteredResults?.[0]) {
      loadFirstResult();
    }
  };

  useDocumentTitle(
    t('searching') + (userInputValue.length > 0 ? ' "' + userInputValue + '"' : '...')
  );

  return (
    <div onKeyDown={handleTab}>
      <div className={styles.searchContainer} style={{borderRadius: '10px'}}>
        <Typeahead.Wrapper
          wrapperRef={wrapperRef}
          onSelection={handleSelection}
          style={{width: '100%'}}
          omitOverlay
        >
          {({getTriggerProps, getContentProps}) => (
            <>
              <Box style={{marginBottom: -16}}>
                <FormRow>
                  <TextInput
                    {...getTriggerProps({
                      onChange: onInputChange,
                      onKeyDown: handleEnter,
                    })}
                    placeholder={t('search_global').toString()}
                    value={userInputValue}
                    withLeftAccessory={
                      <UIIcon
                        size="large"
                        src={SearchLine}
                        className={atoms({color: 'Text Subtle'})}
                      />
                    }
                    autoFocus
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    style={{fontSize: 18, marginLeft: 16, paddingBottom: -16}}
                  />
                </FormRow>
              </Box>
              <FilterBar selectedFilter={selectedFilter} setFilter={setFilterAndLog} />
              <div style={{overflow: 'auto', maxHeight: `calc(100vh - 304px)`}}>
                <Typeahead.Container
                  {...getContentProps()}
                  triggerOffset={0}
                  open
                  className={styles.typeaheadResultsContainer}
                  isEmptyQuery={filteredResults.length === 0}
                  emptyPrompt={
                    <Typeahead.Prompt>
                      <Text color="faint">{t('no_dropboxer')}</Text>
                    </Typeahead.Prompt>
                  }
                  loading={isLoading}
                >
                  <Typeahead.Results
                    size="large"
                    results={filteredResults}
                    renderRow={renderTypeaheadRow}
                    initialResults={INITIAL_RESULTS}
                    maxResults={MAX_RESULTS}
                  />
                </Typeahead.Container>
              </div>
            </>
          )}
        </Typeahead.Wrapper>
      </div>
    </div>
  );
};

const FilterBar = ({
  selectedFilter,
  setFilter,
}: {
  selectedFilter: Filters;
  setFilter: (filter: Filters) => void;
}) => {
  const {isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);
  const externalReleaseFilters = filters.filter(
    (filter) => !excludedFiltersForExternalRelease.includes(filter)
  );
  const searchBarFilters = isDropboxOSEnabled ? externalReleaseFilters : filters;
  return (
    <Split paddingTop="16" paddingBottom="8" alignY="center">
      <Split.Item width="fill">
        <Split gap="8" wrap="wrap">
          {searchBarFilters.map((filter) => (
            <Split.Item key={filter}>
              <Chip
                isSelected={filter === selectedFilter}
                onClick={() => setFilter(filter as Filters)}
                {...withShade({})}
              >
                {t(filter)}
              </Chip>
            </Split.Item>
          ))}
        </Split>
      </Split.Item>
      <Split.Item className={styles2.searchFiltersKeyboard}>
        <Split alignY="center" gap="16">
          <Split.Item>
            <Text color="faint" size="small">
              <Trans
                t={t}
                i18nKey="to_navigate"
                values={{key: '↑ ↓'}}
                components={{b: <Text isBold color="faint" />}}
              />
            </Text>
          </Split.Item>
          <Split.Item>
            <Text color="faint" size="small">
              <Trans
                t={t}
                i18nKey="to_switch_filters"
                values={{key: 'Tab'}}
                components={{b: <Text isBold color="faint" size="small" />}}
              />
            </Text>
          </Split.Item>
        </Split>
      </Split.Item>
    </Split>
  );
};
