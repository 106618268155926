import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Table} from '@dropbox/dig-components/dist/table';
import {CellProps} from '@dropbox/dig-components/dist/table/cell';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

export const LabelGroupCell = ({
  width,
  isBold,
  text,
  subText,
  verticalAlign,
  withLeftAccessory,
  className,
}: {
  verticalAlign?: CellProps['verticalAlign'];
  width?: number;
  isBold?: boolean;
  text: ReactNode;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
  className?: string;
}) => {
  return (
    <Table.Cell verticalAlign={verticalAlign}>
      <Box paddingY={!className ? '12' : undefined} className={className}>
        <LabelGroup
          align={verticalAlign === 'top' ? 'top' : 'center'}
          withLeftAccessory={withLeftAccessory}
          withText={
            <Text isBold={isBold}>
              <div style={{display: 'flex', width, whiteSpace: 'nowrap'}}>
                <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</span>
              </div>
            </Text>
          }
          withSubtext={
            subText && (
              <Text color="faint" size="small">
                <div style={{display: 'flex', width, whiteSpace: 'nowrap'}}>{subText}</div>
              </Text>
            )
          }
        />
      </Box>
    </Table.Cell>
  );
};

export const LabelGroupSubtleCell = ({
  text,
  subText,
  verticalAlign,
  withLeftAccessory,
  className,
}: {
  verticalAlign?: CellProps['verticalAlign'];
  text: ReactNode;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
  className?: string;
}) => {
  return (
    <Table.Cell verticalAlign={verticalAlign}>
      <Box paddingY={!className ? '12' : undefined} className={className}>
        <LabelGroup
          withLeftAccessory={withLeftAccessory}
          withText={<Text size="small">{text}</Text>}
          withSubtext={
            subText && (
              <Text color="faint" size="xsmall">
                {subText}
              </Text>
            )
          }
        />
      </Box>
    </Table.Cell>
  );
};
