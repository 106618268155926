import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {DropboxPaperLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {isMobileAtom} from 'atoms/layout';
import {strategiesByIdAtom, StrategyItem} from 'atoms/strategies';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Breadcrumb} from 'components/DSYS/Breadcrumb';
import {CircleIcon} from 'components/DSYS/CircleIcon';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useColumnResize} from 'components/shared/table/useColumnResize';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useEmployees} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {ReactNode, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {GoalsV2Drawer} from 'views/goals_v2/Drawers/GoalsV2Drawer';
import {SetGoalsV2DrawerDataType} from 'views/goals_v2/Drawers/types';
import {DrawerGoalData} from 'views/goals_v2/types';

import {Filters, TableFilter} from './Filter';
import {useStrategy} from './hooks';
import {getDefaultColumns, Table} from './Table';
import {TimeframeSelector} from './TimeframeSelector';

const CardSkeleton = () => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      display="flex"
      flexDirection={!isMobile ? 'row' : 'column'}
      style={{gap: isMobile ? 2 : 16}}
    >
      {Array.from({length: 2}).map((_, index) => (
        <Skeleton.Box width={236} height={56} key={index} style={{opacity: 0}} />
      ))}
    </Box>
  );
};

const PaperDocCard = ({strategy}: {strategy: StrategyItem}) => (
  <Link
    to={strategy.link}
    hasNoUnderline
    {...withShade({
      className: atoms({
        display: 'block',
        padding: '8',
        borderRadius: 'Medium',
      }),
      style: {width: 220, height: 40},
    })}
  >
    <LabelGroup
      withText={<Text isBold>{strategy.name}</Text>}
      withLeftAccessory={<CircleIcon src={DropboxPaperLine} />}
    />
  </Link>
);

const EmployeeCard = ({employee, extra}: {employee: Employee; extra: ReactNode}) => (
  <Link
    to={`/people/${employee.ldap}`}
    {...withShade({
      className: atoms({
        display: 'block',
        padding: '8',
        borderRadius: 'Medium',
      }),
      style: {width: 220},
    })}
    hasNoUnderline
  >
    <LabelGroup
      withText={
        <Text isBold>
          {employee.name}
          {extra}
        </Text>
      }
      withSubtext={<Truncate>{employee.role}</Truncate>}
      withLeftAccessory={<Avatar user={employee} />}
    />
  </Link>
);

const LoadableEmployeeCard = ({strategy}: {strategy: StrategyItem}) => {
  const isMobile = useAtomValue(isMobileAtom);
  const employees = useEmployees({ldaps: strategy?.owners.map(({ldap}) => ldap) ?? []});
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAllEmployees, setShowAllEmployees] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = document.getElementById('main')?.children[0].clientWidth ?? 1;

      setShowAllEmployees(containerWidth - 450 > employees.length * 220);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [employees]);

  const shownEmployees = showAllEmployees ? employees : employees.slice(0, 1);

  return (
    <Box
      ref={containerRef}
      display="flex"
      flexDirection={!isMobile ? 'row' : 'column'}
      style={{gap: isMobile ? 2 : 16}}
    >
      {shownEmployees.map((employee) => (
        <EmployeeCard
          key={employee.user_id}
          employee={employee}
          extra={
            !showAllEmployees &&
            employees.length > 1 && (
              <Tooltip
                title={employees
                  .slice(1)
                  .map(({name}) => name)
                  .join(', ')}
              >
                <Text color="faint"> + {employees.length - 1 + ' others'}</Text>
              </Tooltip>
            )
          }
        />
      ))}
      <PaperDocCard strategy={strategy} />
    </Box>
  );
};

const LoadedTable = ({
  strategy,
  timeframe,
  filter,
  setSelectedGoal,
}: {
  strategy: StrategyItem;
  timeframe: string;
  filter: Filters;
  setSelectedGoal: (data: DrawerGoalData) => void;
}) => {
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());

  const {data} = useStrategy({strategyId: strategy.id.toString(), timeframe});

  if (!data) {
    return null;
  }

  return (
    <Table
      data={data}
      columnConfigs={columnConfigs}
      dragging={dragging}
      onColumnDrag={getMouseDownHandler}
      setSelectedGoal={setSelectedGoal}
      filter={filter}
    />
  );
};

export const StrategyDetails = ({
  timeframe,
  setTimeframe,
}: {
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
}) => {
  const params = useParams();
  const [filter, setFilter] = useState<Filters>({status: []});
  const strategies = useAtomValue(strategiesByIdAtom);
  const strategy = strategies.get(parseInt(params.id ?? '0', 10));

  const [goalData, setGoalData] = useState<undefined | DrawerGoalData>(undefined);
  const [goalsV2DrawerData, setGoalsV2DrawerData] = useState<SetGoalsV2DrawerDataType>({
    drawerType: 'goal',
    isOpen: false,
    source: '',
  });

  const handleCloseAndResetDrawers = useCallback(() => {
    setGoalsV2DrawerData({drawerType: 'goal', isOpen: false, source: ''});
    setGoalData(undefined);
  }, []);

  const [nextTimeframe, setNextTimeframe] = useState<string>(getNextQuarter(timeframe));

  useDocumentTitle(
    strategy?.name ? `${strategy.name} — ${t('strategy_plural')}` : t('strategy_plural')
  );

  if (!strategy) {
    return <NotFound />;
  }

  return (
    <>
      <Layout.Container>
        <Stack gap="16">
          <Breadcrumb
            path={[
              {to: '/strategies', children: t('strategy_plural')},
              {to: '/strategies', children: '2025'},
              ...(strategy.parent
                ? [{to: `/strategies/${strategy.parent.id}`, children: strategy.parent.name}]
                : []),
              {to: `/strategies/${strategy.id}`, children: strategy.name},
            ]}
          />
          <Title size={24} className={atoms({paddingTop: '10'})}>
            {strategy.name}
          </Title>
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={1}>
              {strategy?.owners && (
                <Suspense fallback={<CardSkeleton />}>
                  <LoadableEmployeeCard strategy={strategy} />
                </Suspense>
              )}
            </Box>
            <Box display="flex" alignItems="center" marginBottom="8" style={{gap: 2}}>
              <TableFilter filter={filter} setFilter={setFilter} />
              <Box
                style={{width: 1, height: 24}}
                className={atoms({
                  borderLeft: 'Solid',
                  borderWidth: '1',
                  borderColor: 'Border Subtle',
                })}
              />
              <TimeframeSelector
                options={['Q1 FY25']}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
              />
            </Box>
          </Box>
        </Stack>
      </Layout.Container>
      <Box marginTop="16">
        <LoadedTable
          strategy={strategy}
          timeframe={timeframe}
          filter={filter}
          setSelectedGoal={(data) => {
            setGoalData(data);
            setGoalsV2DrawerData({
              drawerType: 'goal',
              isOpen: true,
              source: 'strategy',
            });
          }}
        />
      </Box>
      <Suspense fallback={null}>
        {goalData && (
          <GoalsV2Drawer
            disableTitleChange
            drawerType={goalsV2DrawerData.drawerType}
            isOpen={goalsV2DrawerData.isOpen}
            source={goalsV2DrawerData.source}
            goalData={goalData}
            timeframe={timeframe}
            nextTimeframe={nextTimeframe}
            setNextTimeframe={setNextTimeframe}
            onClose={handleCloseAndResetDrawers}
            setGoalData={setGoalData}
            setGoalsV2DrawerData={setGoalsV2DrawerData}
            handleContinueGoalClick={() => {}}
            autoFocus={goalsV2DrawerData.autoFocus}
            showBackButton={goalsV2DrawerData.showBackButton}
          />
        )}
      </Suspense>
    </>
  );
};
