import {Stack} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Project, ProjectData, ProjectDetails, TeamProjectData, TeamWithHierarchy} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Scroller} from 'components/DSYS/Scroller';
import {useProject} from 'components/projects/hooks';
import {ProjectStatusModal} from 'components/projects/ProjectStatusModal';
import {EmptyTable} from 'components/shared/table/EmptyTable';
import {seenUpdatesAtom} from 'components/shared/table/UpdateCell';
import {useColumnResize} from 'components/shared/table/useColumnResize';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {Await, useLoaderData, useLocation, useParams} from 'react-router-dom';

import {ProjectDrawer} from './ProjectDrawer';
import {ProjectHeader} from './ProjectHeader';
import {defaultColumns, ProjectTable} from './Table/ProjectTable';
import {groupByArea, teamToArea} from './teamUtils';
import {filterTable, getDefaultFilter, ProjectTableFilter} from './utils';

const LoadedProjectView = ({
  data,
  area,
  hasMore,
  filter,
  setFilter,
}: {
  area: TeamWithHierarchy;
  data: ProjectData & ProjectDetails;
  hasMore: boolean;
  filter: ProjectTableFilter;
  setFilter: (filter: ProjectTableFilter) => void;
}) => {
  const params = useParams() as {identifier?: string};
  const location = useLocation();
  const seenUpdates = useAtomValue(seenUpdatesAtom);
  const {project, projects: preloadedProjects, ...details} = data;

  const {data: projectsData} = useProject({
    id: params.identifier ?? 'undefined',
    timeframe: filter.availability.join(','),
  });

  const projects = projectsData?.projects ?? preloadedProjects;

  const [sidebarProjectId, setSidebarProjectId] = useState<undefined | number>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [projectModalId, setProjectModalId] = useState<undefined | number>(undefined);
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(defaultColumns);
  useDocumentTitle(project?.title ? project.title : `${area.name} ${t('projects')}`);

  const groupedTeams = useMemo(
    () =>
      Object.values(groupByArea(area, projects ?? [])).filter(
        (teamProjects) => teamProjects.projects.filter(filterTable(filter, seenUpdates)).length > 0
      ),
    [area, projects, filter, seenUpdates]
  );

  useEffect(() => {
    const source = new URLSearchParams(location.search).get('source') ?? location.state?.source;
    if (source) {
      analyticsLogger().logEvent('PROJECT_TABLE_VIEW', {
        source,
        view: project ? 'project' : 'team',
      });
    }
  }, [location.search, location.state?.source, project]);

  const noResults = useMemo(
    () =>
      project
        ? (project.epics?.filter(filterTable(filter, seenUpdates)).length ?? 0) === 0
        : projects?.filter(filterTable(filter, seenUpdates)).length === 0,
    [project, filter, seenUpdates, projects]
  );

  useEffect(() => {
    const filterValues = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (key === 'search') {
          if ((value as string).length) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = true;
          }
        } else if (key === 'progress') {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = Math.floor((value as number) / 10) * 10;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, boolean | number | string | string[]>
    );

    analyticsLogger().logEvent('PROJECT_TABLE_FILTER', {
      view: project ? 'project' : 'team',
      ...filterValues,
    });
  }, [filter, project]);

  useEffect(() => {
    if (location.state?.source) {
      analyticsLogger().logEvent('PROJECT_TABLE_VIEW', {
        source: location.state.source,
        view: project ? 'project' : 'team',
      });
    }
  }, [project, location.state?.source]);

  return (
    <>
      {!project && !hasMore && <ProjectScroller area={area} teams={groupedTeams} filter={filter} />}
      <Layout.Container>
        <ProjectHeader
          isLoading={hasMore}
          area={area}
          totalProjects={data.total_count ?? projects?.length ?? 0}
          data={projects?.length ? projects : (project ?? [])}
          details={details}
          filter={filter}
          setFilter={setFilter}
          onStatusClick={setProjectModalId}
        />
      </Layout.Container>

      <Stack gap="12">
        {noResults ? (
          <EmptyTable
            team={area ?? undefined}
            project={project ?? undefined}
            onFilterReset={() => setFilter(getDefaultFilter())}
          />
        ) : !project ? (
          groupedTeams.map(({team, projects: teamProjects}) => (
            <ProjectTable
              isLoading={hasMore}
              key={team.team_id}
              filter={filter}
              data={teamProjects}
              team={team}
              noResults={noResults}
              onUpdatesClick={(id) => {
                setIsOpen(true);
                setSidebarProjectId(id);
              }}
              onStatusClick={setProjectModalId}
              columnConfigs={columnConfigs}
              dragging={dragging}
              onColumnDrag={getMouseDownHandler}
            />
          ))
        ) : (
          <ProjectTable
            isLoading={hasMore}
            filter={filter}
            data={[project]}
            team={project.team ?? undefined}
            isProjectTable
            hideTeamHeader
            noResults={noResults}
            onUpdatesClick={(id) => {
              setIsOpen(true);
              setSidebarProjectId(id);
            }}
            onStatusClick={setProjectModalId}
            columnConfigs={columnConfigs}
            dragging={dragging}
            onColumnDrag={getMouseDownHandler}
          />
        )}
      </Stack>

      {projectModalId && (
        <ProjectStatusModal
          projectId={projectModalId}
          onClose={() => setProjectModalId(undefined)}
        />
      )}

      <ProjectDrawer
        isOpen={isOpen}
        sidebarProjectId={sidebarProjectId}
        onClose={() => setIsOpen(false)}
        onDidClose={() => setSidebarProjectId(undefined)}
      />
    </>
  );
};

export const ProjectView = () => {
  const data = useLoaderData() as {
    team: TeamProjectData;
    data: Promise<ProjectData & ProjectDetails>;
  };

  const [filter, setFilter] = useState<ProjectTableFilter>(getDefaultFilter());

  return (
    <Suspense
      fallback={
        <Layout.Container>
          <ProjectHeader
            isLoading={true}
            area={data.team.team}
            data={data.team.project ?? []}
            totalProjects={0}
            filter={filter}
            setFilter={setFilter}
            onStatusClick={() => {}}
          />
        </Layout.Container>
      }
    >
      <Await resolve={data.data} errorElement={null}>
        {(projectData) => (
          <LoadedProjectView
            data={projectData}
            area={data.team.team}
            filter={filter}
            hasMore={false}
            setFilter={setFilter}
          />
        )}
      </Await>
    </Suspense>
  );
};

type Node = {label: string; element: HTMLElement};
type NodeData = {parent: Node; children: Node[]};

// TODO: unexport and use above
export const ProjectScroller = ({
  area,
  teams,
  filter,
}: {
  area: TeamWithHierarchy;
  teams: {
    team: TeamWithHierarchy;
    projects: Project[];
  }[];
  filter: ProjectTableFilter;
}) => {
  const seenUpdates = useAtomValue(seenUpdatesAtom);
  const [data, setData] = useState<NodeData[] | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      setData(
        teams
          .map(({team, projects}) => {
            const subTeams = (
              projects
                ?.filter(filterTable(filter, seenUpdates))
                .filter((project) => project.team)
                .map((project) => project.team!)
                .filter(
                  (te, index, self) => self.findIndex((tee) => tee.team_id === te.team_id) === index
                ) ?? []
            ).sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

            const teamArea = teamToArea(area, team.team_id);

            if (!teamArea || !document.getElementById(teamArea.slug ?? '')) {
              return undefined;
            }

            return {
              parent: {
                label: teamArea.name,
                element: document.getElementById(teamArea.slug!)!,
              },
              children: (
                (subTeams.length === 1
                  ? []
                  : filter.team.length
                    ? subTeams.filter((st) => filter.team?.includes(st.name ?? ''))
                    : subTeams
                ).map((child) => {
                  if (!document.getElementById(child.slug ?? '')) {
                    return undefined;
                  }
                  return {
                    label: child.name!,
                    element: document.getElementById(child.slug ?? '')!,
                  };
                }) ?? []
              ).filter(Boolean) as Node[],
            };
          })
          .filter(Boolean) as NodeData[]
      );
    }, 600);
  }, [area, teams, filter, seenUpdates]);

  if (!data || (data.flatMap(({children}) => children).length <= 1 && data.length < 2)) {
    return null;
  }

  const isOrg = area.parent_team === 'c0890d90476c9000d186566da4630000';

  return (
    <Scroller
      data={data}
      width={isOrg ? 125 : 300}
      withChildren={!isOrg}
      onScrollClick={(target) => {
        analyticsLogger().logEvent('PROJECT_TABLE_SCROLL', {view: 'area', target});
        setTimeout(() => window.scrollBy(0, -65), 0); // need this workaround for now
      }}
    />
  );
};
