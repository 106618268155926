import {useMutation, useQuery} from '@tanstack/react-query';
import {
  TrackerCreate,
  TrackerEdit,
  TrackerService,
  WorkstreamCreate,
  WorkstreamEdit,
  WorkstreamService,
} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const useTrackerCreate = () => {
  const {mutateAsync: createTracker, isPending} = useMutation({
    mutationFn: ({data}: {data: TrackerCreate}) =>
      getService(TrackerService).createApiV1TrackerPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['trackers']});
    },
  });

  return {createTracker, isPending};
};

export const useTrackers = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['trackers'],
    queryFn: getService(TrackerService).getAllApiV1TrackerAllGet,
  });

  return {data, isLoading};
};

export const useTrackerEdit = () => {
  const {mutateAsync: editTracker, isPending} = useMutation({
    mutationFn: ({data}: {data: TrackerEdit}) =>
      getService(TrackerService).editApiV1TrackerPut(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['trackers']});
      queryClient.invalidateQueries({queryKey: ['tracker', response]});
    },
  });

  return {editTracker, isPending};
};

export const useTracker = (trackerId?: number | string, reports?: boolean) => {
  const {data, isLoading} = useQuery({
    queryKey: ['tracker', trackerId, reports],
    queryFn: () =>
      getService(TrackerService).getApiV1TrackerIdGet(trackerId?.toString() ?? '', reports),
    enabled: !!trackerId,
  });

  return {data, isLoading};
};

export const useWorkstreamSelf = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstreams', 'self'],
    queryFn: getService(WorkstreamService).getSelfApiV1WorkstreamSelfGet,
  });

  return {data, isLoading};
};

export const useWorkstreamReports = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstreams', 'reports'],
    queryFn: getService(WorkstreamService).getReportsApiV1WorkstreamReportsGet,
  });

  return {data, isLoading};
};

export const useWorkstream = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['workstreams'],
    queryFn: getService(WorkstreamService).getAllApiV1WorkstreamAllGet,
  });

  return {data, isLoading};
};

export const useWorkstreamCreate = () => {
  const {mutateAsync: createWorkstream, isPending} = useMutation({
    mutationFn: ({data}: {data: WorkstreamCreate}) =>
      getService(WorkstreamService).createApiV1WorkstreamPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['workstreams']});
    },
  });

  return {createWorkstream, isPending};
};

export const useWorkstreamEdit = () => {
  const {mutateAsync: editWorkstream, isPending} = useMutation({
    mutationFn: ({reason, data}: {reason?: string; data: WorkstreamEdit}) =>
      getService(WorkstreamService).editApiV1WorkstreamPut(reason || null, data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['workstreams']});
      queryClient.invalidateQueries({queryKey: ['workstream', response]});
    },
  });

  return {editWorkstream, isPending};
};

// export const useWorkstreamDelete = () => {
//   const {mutateAsync: deleteTeam, isPending} = useMutation({
//     mutationFn: (slug: string) => getService(TeamService).archiveApiV1TeamSlugArchivePost(slug),
//     onSettled: (response, _, slug) => {
//       if (!response) {
//         return;
//       }

//       queryClient.invalidateQueries({queryKey: ['teamTree', 'old']});
//       queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'all']});
//       queryClient.invalidateQueries({queryKey: ['teams', 'tree', 'counts', 'all']});
//       queryClient.invalidateQueries({queryKey: ['team', slug]});
//       queryClient.invalidateQueries({
//         queryKey: ['team', 'hierarchy', slug],
//       });
//     },
//   });

//   return {deleteTeam, isPending};
// };

// export const useWorkstreamCreate = () => {
//   const {mutateAsync: createTeam, isPending} = useMutation({
//     mutationFn: ({data}: {data: TeamEdit}) => getService(TeamService).createApiV1TeamPost(data),
//   });

//   return {createTeam, isPending};
// };
