import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {TeamLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import {
  Employee,
  EmployeeWithHierarchy,
  ProfilePage,
  TeamAssociation,
  TeamWithCountsNew,
} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {SavableTeamListTypeahead} from 'components/DSYS/TeamListTypeahead';
import {Title} from 'components/DSYS/Title';
import {AboutMe} from 'components/profile/AboutMe';
import {MyWork} from 'components/profile/MyWork';
import {ProfileBody} from 'components/profile/ProfileBody';
import {ProfileEmployees} from 'components/profile/ProfileEmployees';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useEditTeams} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {OrgChart} from 'pages/OrgChart/Chart';
import {FocusData} from 'pages/OrgChart/helpers';
import {useEffect, useState} from 'react';
import {useLoaderData, useLocation} from 'react-router-dom';

const reportingLineToTree = (reportingLine: Employee[]) => {
  let tree: EmployeeWithHierarchy = {...reportingLine[0], children: []};

  for (const employee of reportingLine.slice(1)) {
    tree = {
      ...employee,
      children: [tree],
    };
  }

  return tree;
};

export const ProfileView = () => {
  const props = useLoaderData() as ProfilePage;

  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [focus, setFocus] = useState<FocusData>(props.employee);

  const {editTeams, isPending} = useEditTeams({id: props.employee.ldap, ldap: props.employee.ldap});

  const [showEditTeams, setShowEditTeams] = useState(false);

  const {isDropboxOSEnabled} = useAtomValue(growthbookCacheAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  useDocumentTitle(props?.employee.name ?? '');

  useEffect(() => {
    setFocus(props.employee);
    setExpanded(false);
  }, [props.employee]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (props.employee) {
      analyticsLogger().logEvent('PROFILE_OPENED', {
        level: props.employee.level ?? undefined,
        source: location.state?.source,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.employee]);

  if (!props || !props.employee) {
    return <NotFound />;
  }

  const directReportLdaps = props.directReports.map((employee) => employee.ldap);
  const peers = (props.teamMembers || []).filter((teamMember) => {
    const isManager = teamMember.user_id === props.employee.manager_id;
    const isDirectReport = directReportLdaps.indexOf(teamMember.ldap) !== -1;
    // Peer cannot be user's direct report or manager
    return !isManager && !isDirectReport;
  });

  // const profileActionLog = (action: string) =>
  //   analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  const onCancel = () => {
    setShowEditTeams(false);
  };

  const onSave = async (selections: TeamAssociation[]) => {
    try {
      await editTeams({
        teams: selections.map((assoc) => ({
          team_id: assoc.team.team_id,
          slug: assoc.team.slug ?? '',
          allocation: assoc.allocation,
        })),
      });

      setSnackbarMessage({text: t('saved')});
      setShowEditTeams(false);
    } catch (e) {
      setSnackbarMessage({text: t('error_saving')});
    }
  };

  const onExpandChart = (expand: boolean) => {
    setShowEditTeams(false);
    setExpanded(expand);
  };

  return (
    <Layout.InlineDrawerContainer
      open={showEditTeams}
      breadcrumb={[
        {children: t('people'), to: '/people'},
        ...(props.reportingLine ?? [])
          .map(({name, ldap}) => ({
            key: ldap,
            children: name,
            to: `/people/${ldap}`,
          }))
          .reverse(),
      ]}
      drawerHeader={<Title size={18}>{t('edit_teams')}</Title>}
      drawerIcon={TeamLine}
      drawerBody={
        <SavableTeamListTypeahead
          isPending={isPending}
          employee={props.employee}
          memberSort={(a, b) => (a.team.name ?? '').localeCompare(b.team.name ?? '')}
          onSave={onSave}
          onCancel={onCancel}
          withRightAccessory={(assoc) => <Text>{assoc.allocation}%</Text>}
        />
      }
      onClose={() => setShowEditTeams(false)}
      size="condensed"
    >
      <Stack gap="24">
        <Box
          opacity={expanded ? 0 : 1}
          paddingBottom="8"
          style={{
            transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
          }}
        >
          <ProfileBody
            employee={props.employee}
            employeeTeams={props.employeeTeams}
            admin={props.admin}
            onEditTeams={() => setShowEditTeams(true)}
          />
        </Box>

        {props.reportingLine.length > 1 && (
          <OrgChart
            overrideTree={reportingLineToTree(props.reportingLine)}
            toggle={{
              employee: props.employee,
              team: getTeamWithMostMembers(props.employeeTeams),
            }}
            focus={focus}
            setFocus={setFocus}
            expanded={expanded}
            setExpanded={onExpandChart}
          />
        )}

        <Box opacity={expanded ? 0 : 1}>
          {props.directReports && props.directReports.length > 0 && (
            <ProfileEmployees
              sectionTitle={t('reports')}
              employees={props.directReports}
              employee={props.employee}
              source="reports"
            />
          )}
        </Box>

        <Stack className={atoms({opacity: expanded ? 0 : 1})} gap="24">
          {peers.length > 0 && (
            <ProfileEmployees
              sectionTitle={t('peers')}
              employees={peers}
              employee={props.employee}
              source="peers"
            />
          )}

          {!isDropboxOSEnabled && (
            <MyWork
              employee={props.employee}
              hasGoals={props.hasGoals}
              hasProjects={props.hasProjects}
            />
          )}

          <AboutMe employee={props.employee} workingWithMe={props.workingWithMe} />
        </Stack>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

const getTeamWithMostMembers = (teams: TeamWithCountsNew[]): TeamWithCountsNew | undefined => {
  return teams.length === 0
    ? undefined
    : teams.reduce((maxTeam, currentTeam) =>
        currentTeam.total_employee_count > maxTeam.total_employee_count ? currentTeam : maxTeam
      );
};
