import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Select, TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Cluster, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine, PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, EmployeeLdap, Workstream, WorkstreamCreate, WorkstreamEdit} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

const EmployeeSelect = ({
  selected,
  onSelect,
}: {
  selected?: EmployeeLdap;
  onSelect: (employee: Employee) => void;
}) => {
  // add default selected employees

  const initialSelectedEmployees = selected ? [selected as unknown as Employee] : [];
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(initialSelectedEmployees);
  const selectEmployee = (employee: Employee) => {
    setSelectedEmployees([employee]);
    onSelect(employee);
  };

  const selectEmployees = (employees: Employee[]) => {
    setSelectedEmployees([...employees]);
    if (employees.length > 0) {
      onSelect(employees[0]);
    }
  };

  const removeEmployee = (employee: Employee) => {
    setSelectedEmployees(selectedEmployees.filter((e) => e.ldap !== employee.ldap));
  };

  return (
    <PeopleSearchMenu
      selectedEmployees={selectedEmployees ?? []}
      onRemoveEmployee={removeEmployee}
      onSelectEmployee={selectEmployee}
      onSelectEmployees={selectEmployees}
      showReportingLine={true}
      minHeight="48px"
      isSingleSelect={true}
      searchIconPosition="left"
      allowSelf={true}
    />
  );
};

export const WorkstreamModify = ({
  workstream,
  onSubmit,
  isEditing,
  isPending,
}: {
  onSubmit: (data: {data: WorkstreamCreate | WorkstreamEdit; reason?: string}) => Promise<number>;
  workstream?: Workstream;
  isPending: boolean;
  isEditing?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editorValue, setEditorValue] = useState<EditorState>();
  const autofocus = location.state?.autofocus;
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [reason, setReason] = useState<string | undefined>();
  const [workstreamDri, setWorkstreamDri] = useState<EmployeeLdap | undefined>(
    workstream?.employee_associations.find(({dri}) => dri === 'dri')?.employee
  );

  const [reporter, setReporter] = useState<EmployeeLdap | undefined>(
    workstream?.employee_associations.find(({dri}) => dri === 'reporter')?.employee
  );

  useDocumentTitle(isEditing ? t('edit_workstream') : t('add_workstream'));

  useEffect(() => {
    analyticsLogger().logEvent('WORKSTREAM_MODIFY_VIEW', {edit: Boolean(isEditing), autofocus});
  }, [isEditing, autofocus]);

  const [workstreamUpdate, setWorkstreamUpdate] = useState<WorkstreamEdit>(
    workstream
      ? {
          id: workstream.id,
          name: workstream.name,
          done: workstream.done,
          start_date: workstream.start_date,
          end_date: workstream.end_date,
          status_cadence: workstream.status_cadence,
          status_due: workstream.status_due,
          employee_associations: workstream.employee_associations.map((assoc) => ({
            user_id: assoc.employee.user_id,
            dri: assoc.dri,
          })),
        }
      : ({
          status_cadence: 'weekly',
          status_due: 'monday',
          end_date: null,
        } as unknown as WorkstreamEdit)
  );

  const changedRequiredField =
    workstream &&
    (workstream.done !== editorValue?.toString() ||
      workstream.end_date !== workstreamUpdate.end_date);

  const hasError = useMemo(() => {
    const {name} = workstreamUpdate;

    if (!name) {
      return true;
    }

    if (!workstreamDri) {
      return true;
    }

    if (!reason && changedRequiredField) {
      return true;
    }

    return false;
  }, [changedRequiredField, workstreamDri, reason, workstreamUpdate]);

  const handleSubmit = async () => {
    try {
      await onSubmit({
        reason,
        data: {
          id: workstreamUpdate.id,
          name: workstreamUpdate.name,
          done: editorValue?.toString() ?? workstreamUpdate.done,
          start_date: new Date().toISOString(),
          end_date: workstreamUpdate.end_date,
          status_cadence: workstreamUpdate.status_cadence,
          status_due: workstreamUpdate.status_due,
          employee_associations: [
            ...(workstreamDri ? [{dri: 'dri', user_id: workstreamDri?.user_id}] : []),
            ...(reporter ? [{dri: 'reporter', user_id: reporter?.user_id}] : []),
          ],
          // .filter(Boolean) as WorkstreamEmployeeEdit[]
          // .map((e, index) => ({
          //   user_id: e.user_id,
          //   dri: e.dri,
          // })),
        },
      });

      setSnackbarMessage({text: t('saved')});

      navigate(`/workstreams/${loggedInEmployee.ldap}`, {
        state: {source: isEditing ? 'modify' : 'create'},
      });
    } catch (e: any) {
      setSnackbarMessage({text: e});
    }
  };

  if (!loggedInEmployee?.email) {
    return null;
  }

  return (
    <Layout.InlineDrawerContainer
      open={isDrawerOpen}
      size="condensed"
      drawerHeader={<Title size={18}>{t('members_title')}</Title>}
      drawerIcon={PersonMultipleLine}
      drawerBody={<></>}
      onClose={() => setDrawerOpen(false)}
    >
      <Stack align="start">
        <Header isEditing={isEditing} />

        <FormRow>
          <FormLabel
            withInput={
              <>
                <TextInput
                  required
                  autoFocus={!autofocus}
                  isInvalid={hasError && !workstreamUpdate.name}
                  size="large"
                  width="100%"
                  placeholder={t('start_writing_placeholder')}
                  value={workstreamUpdate.name}
                  onChange={(e) => setWorkstreamUpdate((p) => ({...p, name: e.target.value}))}
                />

                {/* <Text monospace className={atoms({margin: '16', paddingY: '8'})}>
                    https://os.dropbox.com/{teamUpdate.name.toLowerCase().replace(' ', '-')}
                  </Text> */}
              </>
            }
          >
            {t('workstream_edit_name')}
          </FormLabel>
        </FormRow>

        <FormRow>
          <Split gap="16">
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_dri')}</FormLabel>
              <EmployeeSelect
                selected={workstreamDri}
                onSelect={(employee) => {
                  setWorkstreamDri(employee);
                }}
              />
            </Split.Item>
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_reporter')}</FormLabel>
              <EmployeeSelect
                selected={reporter}
                onSelect={(employee) => {
                  setReporter(employee);
                }}
              />
            </Split.Item>
          </Split>
        </FormRow>

        <FormRow>
          <FormLabel>{t('workstream_edit_done')}</FormLabel>
          <RichTextArea
            editable
            value={workstreamUpdate.done}
            source="workstream-done"
            placeholder={t('team_edit_description_placeholder').toString()}
            onChange={setEditorValue}
            // isInvalid={hasError && !workstreamUpdate.done?.length}
            // placeholder={t('team_edit_description_placeholder')}
            // size="large"
            // value={workstreamUpdate.done}
            // resizable="auto"
            // onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            //   setWorkstreamUpdate((p) => ({...p, done: e.target.value}))
            // }
          />
        </FormRow>

        <FormRow>
          <Split gap="16">
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_cadence', {when: 'weekly'})}</FormLabel>
              <Select
                id={'select-day-of-week'}
                value={workstreamUpdate.status_due ?? 'monday'}
                onChange={(value) => setWorkstreamUpdate((p) => ({...p, status_cadence: value}))}
              >
                <Select.Option value="monday">Monday</Select.Option>
                <Select.Option value="tuesday">Tuesday</Select.Option>
                <Select.Option value="wednesday">Wednesday</Select.Option>
                <Select.Option value="thursday">Thursday</Select.Option>
                <Select.Option value="friday">Friday</Select.Option>
                <Select.Option value="saturday">Saturday</Select.Option>
                <Select.Option value="sunday">Sunday</Select.Option>
              </Select>
            </Split.Item>
            <Split.Item width="fill">
              <FormLabel>{t('workstream_edit_end_date')}</FormLabel>

              <DatePickerInput
                id="choose-date"
                autocomplete="off"
                value={workstreamUpdate.end_date ? new Date(workstreamUpdate.end_date) : null}
                onChange={(date) =>
                  setWorkstreamUpdate((p) => ({...p, end_date: date ? date.toISOString() : null}))
                }
                // onSelection={handleSelection}
                // isDateBlocked={isDateBlocked}
              />
            </Split.Item>
          </Split>
        </FormRow>

        {changedRequiredField && (
          <FormRow>
            <Box
              backgroundColor="Background Subtle"
              padding="16"
              borderRadius="Medium"
              marginTop="16"
            >
              <Cluster className={atoms({marginBottom: '8'})}>
                <Text>
                  <Trans
                    i18nKey="workstream_edit_update_reason"
                    t={t}
                    components={{b: <Text isBold />}}
                  />
                </Text>

                <Button variant="transparent" size="small" className={atoms({marginLeft: 'auto'})}>
                  {t('undo_changes')}
                </Button>
              </Cluster>
              <TextArea
                isInvalid={hasError && !reason}
                placeholder={t('team_edit_description_placeholder')}
                size="large"
                value={reason}
                resizable="auto"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
              />
            </Box>
          </FormRow>
        )}

        <FormRow>
          <EditSaveButtons
            cta={'Save workstream'}
            disableSave={hasError}
            isLoading={isPending}
            handleCancelClick={() =>
              /* eslint-disable-next-line*/
              /* @ts-ignore */
              navigate(-1, {
                state: {source: isEditing ? 'modify' : 'create'},
              })
            }
            handleSaveClick={handleSubmit}
          />
        </FormRow>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

const Header = ({isEditing}: {isEditing?: boolean}) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        variant="transparent"
        style={{position: 'absolute', marginTop: 4, marginLeft: -40}}
        onClick={() =>
          /* eslint-disable-next-line*/
          /* @ts-ignore */
          navigate(-1, {
            state: {source: isEditing ? 'modify' : 'create'},
          })
        }
      >
        <UIIcon src={ArrowLeftLine} />
      </IconButton>
      <Split alignY="center" paddingBottom="16" gap="6">
        <Split.Item width="fill">
          <Title size={18}>{isEditing ? t('edit_workstream') : t('add_workstream')}</Title>
        </Split.Item>
      </Split>
    </>
  );
};
