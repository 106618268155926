import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {isCommentInputFocusedAtom} from 'atoms/comment';
import {Thread} from 'client';
import {Highlightable} from 'components/comments/Highlightable';
import Range from 'components/comments/Range';
import {CommentThreadComponent} from 'components/comments/Thread';
import {t} from 'i18next';
import {useAtom} from 'jotai';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';

import styles from './WorkstreamComments.module.css';

const fake_threads: Thread[] = [
  {
    start: 0,
    end: 10,
    attached_text: 'Hello World',
    attached_to_type: 'workstream',
    id: '1',
    created_at: '2021-10-10',
    comments: [],
    employee: {
      user_id: '1',
      name: 'Kyle Shay',
      ldap: 'kshay',
      role: 'Software Engineer',
      level: 'IC4',
    },
    is_resolved: false,
  },
];

const getInitialRanges = (fullText: string, threads: Thread[]) => {
  return threads
    .filter(
      (thread) =>
        fullText.substring(thread.start ?? -1, (thread.end ?? -1) + 1) === thread.attached_text &&
        thread.attached_to_type === 'workstream' &&
        thread.is_resolved === false
    )
    .map((thread) => {
      return new Range(thread.start ?? 0, thread.end ?? 0, thread.attached_text ?? '');
    });
};

const getSelectedThread = (threads: Thread[], range: Range) => {
  return threads.find((thread) => {
    return thread.start === range.start && thread.end === range.end && thread.is_resolved === false;
  });
};

export const WorkstreamComments = ({text}: {text: ReactNode}) => {
  // const [shouldRefetch, setShouldRefetch] = useState(true);

  // const threads = useGoalThreads({goalId, shouldRefetch});
  const initialRanges = getInitialRanges(text?.toString() ?? '', fake_threads);

  const [ranges, setRanges] = useState<Range[]>(initialRanges);

  const [highlightedRange, setHighlightedRange] = useState<Range | null>(null);

  const onTextHighlightedCallback = (range: Range) => {
    setRanges([...ranges, range]);
    setHighlightedRange(range);
  };

  const onMouseOverHighlightedWordCallback = (range: Range) => {
    console.log('onMouseOverHighlightedWordCallback', range);
  };

  // useEffect(() => {
  //   // Function to call when the window is focused
  //   // const handleFocus = () => {
  //   //   setShouldRefetch(true);
  //   // };

  //   // // Function to call when the window is blurred
  //   // const handleBlur = () => {
  //   //   setShouldRefetch(false);
  //   // };

  //   // Add event listeners
  //   window.addEventListener('focus', handleFocus);
  //   window.addEventListener('blur', handleBlur);

  //   // Remove event listeners on cleanup
  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //     window.removeEventListener('blur', handleBlur);
  //   };
  // }, []); // Empty array ensures this effect runs only once on mount

  // useEffect(() => {
  //   setRanges(getInitialRanges(text, threads));
  // }, [text, goalId, keyResultId, threads, type]);

  const tooltipRenderer = (
    lettersNode: React.JSX.Element[],
    range: Range,
    rangeIndex: number,
    onMouseOverHighlightedWord: (range: Range, visible: boolean) => void
  ) => {
    return (
      <TooltipComponent
        lettersNode={lettersNode}
        range={range}
        rangeIndex={rangeIndex}
        onMouseOverHighlightedWord={onMouseOverHighlightedWord}
        ranges={ranges}
        setRanges={setRanges}
        targetText={text?.toString() ?? ''}
        highlightedRange={highlightedRange}
        setHighlightedRange={setHighlightedRange}
      />
    );
  };

  return (
    <Highlightable
      ranges={ranges}
      enabled={true}
      onTextHighlighted={onTextHighlightedCallback}
      id={'text-selector'}
      onMouseOverHighlightedWord={onMouseOverHighlightedWordCallback}
      highlightStyle={{
        borderBottom: '2px solid var(--dig-color__warning__surface--state-2)',
        paddingBottom: '3px',
      }}
      rangeRenderer={tooltipRenderer}
      text={text?.toString() ?? ''}
      style={{}}
    />
  );
};

const TooltipComponent = ({
  lettersNode,
  range,
  setRanges,
  targetText,
  highlightedRange,
  setHighlightedRange,
}: {
  lettersNode: React.JSX.Element[];
  range: Range;
  rangeIndex: number;
  onMouseOverHighlightedWord: (range: Range, visible: boolean) => void;
  ranges: Range[];
  setRanges: React.Dispatch<React.SetStateAction<Range[]>>;
  targetText: string;
  highlightedRange: Range | null;
  setHighlightedRange: React.Dispatch<React.SetStateAction<Range | null>>;
}) => {
  const threads = fake_threads;
  const selectedThread = fake_threads[0];

  const triggerRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(highlightedRange == range);

  // const threads = useGoalThreads({goalId});
  const [isHovered, setIsHovered] = useState(false);
  const [isConfirmLeaveModalOpen, setConfirmLeaveModalOpen] = useState(false);

  const [commentInput, setCommentInput] = useState('');
  const [isCommentInputFocused, setIsCommentInputFocused] = useAtom(isCommentInputFocusedAtom);
  // const selectedThread = useMemo(
  //   () => getSelectedThread(threads, range),
  //   [threads, range, keyResultId, goalId]
  // );

  const onCloseClick = () => {
    setShowTooltip(false);
    setIsHovered(false);
    setIsCommentInputFocused(false);
    if (!commentInput && !getSelectedThread(threads, range)) {
      setRanges((currentRanges) => currentRanges.filter((r) => r !== range));
    } else if (commentInput) {
      setConfirmLeaveModalOpen(true);
    }
    setHighlightedRange(null);
  };

  const onConfirmLeaveDraftComment = useCallback(() => {
    setCommentInput('');
    setConfirmLeaveModalOpen(false);
    // if (!selectedThread) {
    //   setRanges(ranges.filter((r) => r !== range));
    // }
  }, []);

  const onSelectedTextClicked = () => {
    setShowTooltip(true);
    setHighlightedRange(range);
  };

  const onRequestClose = useCallback(() => {
    setConfirmLeaveModalOpen(false);
    setHighlightedRange(range);
    setShowTooltip(true);
  }, [range, setHighlightedRange]);

  useEffect(() => {
    if (highlightedRange == range) {
      setShowTooltip(true);
    }
  }, [highlightedRange, range]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isCommentInputFocused) {
        return;
      }
      if (!(e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        onConfirmLeaveDraftComment();
      } else if (!(e.ctrlKey || e.metaKey) && e.key === 'Escape') {
        setConfirmLeaveModalOpen(false);
        if (range == highlightedRange) {
          setShowTooltip(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [highlightedRange, isCommentInputFocused, onConfirmLeaveDraftComment, onRequestClose, range]);

  return (
    <>
      <span
        ref={triggerRef}
        onMouseDown={() => onSelectedTextClicked()}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor:
            showTooltip || isHovered
              ? 'var(--dig-color__warning__surface--state-2)'
              : 'transparent',
          paddingBottom: '3px',
          paddingTop: '3px',
        }}
      >
        {lettersNode}
      </span>
      <Tooltip.Control
        className={!selectedThread && isCommentInputFocused ? styles.tooltipEmpty : styles.tooltip}
        auto
        inverse={false}
        open={showTooltip}
        triggerRef={triggerRef}
        variant="rich"
        placement="bottom"
        maxWidth={315}
        openDelay={30}
      >
        <CommentThreadComponent
          goalId={0}
          keyResultId={0}
          thread={selectedThread}
          range={range}
          onClose={onCloseClick}
          commentInput={commentInput}
          setCommentInput={setCommentInput}
          targetText={targetText}
        />
      </Tooltip.Control>
      <Modal
        open={isConfirmLeaveModalOpen}
        isCentered
        width="small"
        withCloseButton="Close"
        aria-label="Are you sure you want to leave?"
        onRequestClose={onRequestClose}
      >
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title>{t('discard_comment')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('get_rid_of_comment_you_wrote')}</Modal.Body>
        <Modal.Footer>
          <Button variant="opacity" onClick={onRequestClose}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={onConfirmLeaveDraftComment}>
            {t('discard')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
