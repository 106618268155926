import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleLine, CloseLine, MoreHorizontalLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Thread} from 'client';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useRef} from 'react';

import {CommentInputComponent} from './CommentInput';
import {CommentsComponent} from './Comments';
import {useThreadService} from './hooks';
import Range from './Range';

export const CommentThreadComponent = ({
  goalId,
  keyResultId,
  thread,
  range,
  onClose,
  commentInput,
  setCommentInput,
  targetText,
}: {
  goalId: number;
  keyResultId?: number;
  thread: Thread | undefined;
  range: Range;
  onClose: () => void;
  commentInput: string;
  setCommentInput: React.Dispatch<React.SetStateAction<string>>;
  targetText: string;
}) => {
  const threadRef = useRef<HTMLDivElement>(null);

  useOutsideClick(threadRef, onClose);

  return (
    <>
      <div ref={threadRef}>
        {thread && (
          <>
            <ThreadHeader thread={thread} onClose={onClose} />
            <CommentsComponent thread={thread} range={range} />
          </>
        )}
        <CommentInputComponent
          goalId={goalId}
          range={range}
          thread={thread}
          keyResultId={keyResultId}
          text={commentInput}
          setCommentInput={setCommentInput}
          targetText={targetText}
        />
      </div>
    </>
  );
};

const ThreadHeader = ({thread, onClose}: {thread: Thread; onClose: () => void}) => {
  const {deleteThread, updateThread} = useThreadService();
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const handleSelection = () => {
    deleteThread({threadId: thread.id});
  };

  const handleResolve = () => {
    updateThread({
      threadId: thread.id,
      data: {is_resolved: true, is_deleted: false, attached_to_type: thread.attached_to_type},
    });
  };

  const isThreadOwner = employee.user_id === thread.employee.user_id;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      padding="8"
      borderBottom="Solid"
      borderColor="Border Subtle"
    >
      <Menu.Wrapper onSelection={handleSelection} shouldPropagateClickOutsideMouseEvents>
        {({getContentProps, getTriggerProps}) => (
          <>
            {isThreadOwner && (
              <IconButton {...getTriggerProps()} variant="borderless" shape="circular">
                <UIIcon src={MoreHorizontalLine} />
              </IconButton>
            )}
            <Menu.Content {...getContentProps()} minWidth="200px">
              <Menu.Segment>
                <Menu.ActionItem key="delete" value="delete">
                  {t('delete')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
      <IconButton variant="borderless" shape="circular" size="standard" onClick={handleResolve}>
        <UIIcon src={CheckmarkCircleLine}></UIIcon>
      </IconButton>
      <IconButton variant="borderless" shape="circular" onClick={onClose}>
        <UIIcon src={CloseLine}></UIIcon>
      </IconButton>
    </Box>
  );
};
